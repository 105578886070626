import Text from "../../../../components/form/input/Text";
import RadioChoice from "../../../../components/form/input/RadioChoice";
import {
    Col,
    Container,
    Row
} from "react-bootstrap";

const BillingForm = props => {

    const rePrice = props.inputs.re2020.exists === "true"? parseInt(props.inputs.re2020.price, 10) : null;
    const prestationPrice = parseInt(props.inputs.price, 10);

    const totalPrice = prestationPrice + rePrice || prestationPrice || rePrice || 0;
    const accompte = Math.round(0.3*totalPrice);

    console.log(isNaN(totalPrice))
    console.log(totalPrice)

    return(
        <>
            <Container className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Préstation</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                            <RadioChoice
                                legend={"Étude de faisabilité"}
                                id={"feasibilityStudy"}
                                name={"feasibilityStudy"}
                                items={[{label: "Non", value: "false",checked: props.inputs.feasibilityStudy === "false"}, {label: "Oui", value: "true", checked: props.inputs.feasibilityStudy === "true"}]}
                                onChange={props.setInput}
                            />
                            </Col>
                            <Col>
                                <RadioChoice
                                    legend={"Plan d'aménagement intérieur (2D)"}
                                    id={"indoor2D"}
                                    name={"indoor2D"}
                                    items={[{label: "Non", value: "false",checked: props.inputs.indoor2D === "false"}, {label: "Oui", value: "true", checked: props.inputs.indoor2D === "true"}]}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RadioChoice
                                    legend={"3D extérieur"}
                                    id={"outdoor3D"}
                                    name={"outdoor3D"}
                                    items={[{label: "Non", value: "false",checked: props.inputs.outdoor3D === "false"}, {label: "Oui", value: "true", checked: props.inputs.outdoor3D === "true"}]}
                                    onChange={props.setInput}
                                />
                            </Col>
                            <Col>
                                <RadioChoice
                                    legend={"Pièces à fournir pour la demande d'autorisation"}
                                    id={"documentsToProvide"}
                                    name={"documentsToProvide"}
                                    items={[{label: "Non", value: "false",checked: props.inputs.documentsToProvide === "false"}, {label: "Oui", value: "true", checked: props.inputs.documentsToProvide === "true"}]}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RadioChoice
                                    legend={"3D intérieur"}
                                    id={"indoor3D"}
                                    name={"indoor3D"}
                                    items={[{label: "Non", value: "false",checked: props.inputs.indoor3D === "false"}, {label: "Oui", value: "true", checked: props.inputs.indoor3D === "true"}]}
                                    onChange={props.setInput}
                                />
                            </Col>
                            <Col>
                                <RadioChoice
                                    legend={"Réglementation environnementale (RE2020)"}
                                    id={"re2020-exists"}
                                    name={"exists"}
                                    items={[{label: "Non", value: "false",checked: props.inputs.re2020.exists === "false"}, {label: "Oui", value: "true", checked: props.inputs.re2020.exists === "true"}]}
                                    onChange={e => props.setInputObject(e, "re2020")}
                                />
                            </Col>
                        </Row>
                        {
                            props.inputs.re2020.exists === "true" &&
                            <Row>
                                <Col>
                                    <Text
                                        id={"re-price"}
                                        name={"price"}
                                        label={"Montant de la RE2020"}
                                        value={props.inputs.re2020.price}
                                        onChange={e => props.setInputObject(e, "re2020")}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <Text
                                    id={"price"}
                                    name={"price"}
                                    label={"Montant de la prestation"}
                                    value={props.inputs.price}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"d-flex align-items-center"}>
                                <p>Montant total (HT) : {totalPrice} €</p>
                            </Col>
                        <Row>
                            <Col className={"d-flex align-items-center"}>
                                <p>Dont 30% à la signature du présent accord, soit {accompte} €</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"d-flex align-items-center"}>
                                <p>Dont 70% le jour du dépot du permis, soit {totalPrice - accompte} €</p>
                            </Col>
                        </Row>
                        <RadioChoice
                            legend={"Mode de paiement"}
                            id={"paymentMode"}
                            name={"paymentMode"}
                            items={[{label: "Chèque", value: "check",checked: props.inputs.paymentMode === "check"}, {label: "Espèce", value: "species", checked: props.inputs.paymentMode === "species"}]}
                            onChange={props.setInput}
                        />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default BillingForm;