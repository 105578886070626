import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/button.css';
import './css/color.css';
import './css/font.css';
import './css/footer.css';
import './css/form.css';
import './css/index.css';
import './css/utility.css';
import Navbar from "./components/navbar/MaNavbar";
import NewProject from "./pages/newProject/NewProject";
import reportWebVitals from "./reportWebVitals";
import Footer from "./components/footer/Footer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <div className="App">
          <Navbar />
          <NewProject  />
          <Footer />
      </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
