import {useState} from "react";
import "../input/style.css";

const Select = props => {
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(false);

    const handleOpen = () => {
        setOpened(true);
    }

    const handleClose = () => {
        setOpened(false);
    }

    const handleSelected = selected => {
        setSelected(selected)
    }

    const handleChange = event => {
        props.onChange(event);
        /*event.target.value !== ""?*/ handleSelected(true)// : handleSelected(false);
        handleClose();
    }

    return (
        <div className="input-group">
            <select
                name={props.name}
                value={props.value}
                aria-selected={props.value !== ""}
                onChange={handleChange}
                onClick={opened? handleClose : handleOpen}
            >
                <option disabled={true} value={""} hidden={true}>{props.placeholder}</option>
                { props.options.map( option => <option key={"select-"+option} value={option} >{option}</option> ) }
            </select>
            <span className="highlight"/>
            <label className="text-label">{props.label}</label>
        </div>
    );
}

export default Select;