import {useState} from "react";
import "./style.css";

const TextArea = props => {
    const [error, setError] = useState("");

    return(
        <div className="input-group">
            <textarea
                className="input-writeable"
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                required
            />
            <span
                className="bar"
            />
            <label
                className="text-label">
                {props.label}
            </label>
            {error && <p>{error}</p>}
        </div>
    );
}

export default TextArea;