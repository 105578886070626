import logo from "../../images/svg/logos/logo_blanc.svg";
import {
    Container,
    Navbar,
    Nav
} from "react-bootstrap";
import "./style.css"

const MaNavbar = props => {
    return(
        <header>
            <Navbar className="maNavbar text-white" bg={"dark"} variant={"dark"}>
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            src={logo}
                            width="70"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </Navbar.Brand>
                    <Nav>
                        <Nav.Link href="#deets">Accueil</Nav.Link>
                        <Nav.Link href="#deets">Formulaire</Nav.Link>
                        <Nav.Link href="#deets">Contact</Nav.Link>
                        <Nav.Link href="#deets">A propos</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </header>
    );
}

export default MaNavbar;