import server from "../../../api/server";
import React, {useEffect, useRef, useState} from "react";
import Stepper from "../../../components/stepper/Stepper";
import LandForm from "./one/LandForm";
import MaterialsForm from "./three/MaterialsForm";
import PrincipalApplicantForm from "./four/PrincipalApplicantForm";
import UrbanismRulesForm from "./two/UrbanismRulesForm";
import BillingForm from "./five/BillingForm";
import {
    Col,
    Row
} from "react-bootstrap";
import ReactToPrint, {useReactToPrint} from "react-to-print";

const Steps = props => {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'urbanisme-rules',
        onAfterPrint: () => console.log('Print success')
    });

    ////////// States //////////
    const [currentStep, setCurrentStep] = useState(0);

    const [landFormInputs, setLandFormInputs] = useState({
        address: "",
        zipcode: "",
        city: "",
        cadastralReferences: [
            {prefix: "000", section: "", number: ""}
        ],
        lots: [],
        area: "",
        waterServicing: null,
        electricityServicing: null,
        sanitation: { type: null, servicing: null},
        cu: {present: null, authorizationNumber: "", deliveredDate: ""},
        pa: {present: null, authorizationNumber: "", deliveredDate: ""},
        dp: {present: null, authorizationNumber: "", deliveredDate: ""}
    });

    const [urbanismRulesFormInputs, setUrbanismRulesFormInputs] = useState({
        projectType: "",
        pluZone: "",
        pluLink: "",
        abf: null,

        constructionPossibilities: [],
        constructibilityBand: {exists: null, length: ""},
        distanceFromRoad: "",
        distanceFromLimits: "",
        distanceFromLandEnd: "",
        distanceBetweenConstructions: "",
        maximalFootPrint: "",
        gutterTo: "",
        gutterMaxHeight: "",
        ridgeMaximalHeight: "",
        roofEnvelopVolume: {exists: null, height: ""},
        openSpaceCoefficient: "",

        urbanismComment: "",
        implantingComment: "",
        typologyComment: "",
        heightComment: "",
        floorSpacesComment: "",

        refurbishment: {types: [], colors: []},
        refurbishmentComment: "",

        carpentries: {
            openingsHigherThanWide: {exists: null, minimumRelation: ""},
            authorizedMaterials: [],
            forbiddenColors: [],
            authorizedShutters: []
        },
        carpentrieComment: "",

        roofing: {
            authorizedRoofs: [],
            authorizedRoofSlop: {min: "", max: ""},
            authorizedColors: [],
            unitPerSquareMeter: "",
        },
        roofingComment: "",

        fenceComment: "",

        minCarParkPlace: "",
        coveredCarParkPlace: {exists: null, min: ""},
        carParkComment: ""
    });

    const [materialsFormInputs, setMaterialsFormInputs] = useState({
        types: [],
        annexes: [],
        foundations: [],
        typologieComment: "",

        refurbishment: {type: "", color: ""},
        refurbishmentComment: "",

        cover: {material: "", colors: []},

        modenatures: [],
        modenatureComment: "",

        carpentries: {materials: [], colors: []},
        carpentrieComment: "",


        shutters: [],
        shutterComment: "",

        roofs: [],
        roofComment: "",

        gutter: {heightTo: "", height: "", material: "", color: ""},
        gutterComment: "",

        frontFence: {frontFence: null, fences: []},
        backFence: {backFence: null, fences: []},
        rightFence: {rightFence: null, fences: []},
        leftFence: {leftFence: null, fences: []},
        betweenLotFence: {exists: null, fences: []},
        fenceComment: "",

        portals: [],
        portalComment: "",

        gates: [],
        gateComment: "",

        pathways: [],
        pathwayComment: "",

        projectShortDescription: "",
        projectFullDescription: ""
    });

    const [applicantsFormInputs, setApplicantsFormInputs] = useState([
        {
            civility: "",
            firstname: "",
            lastname: "",
            birthdate: "",
            birthZipcode: "",
            birthCity: "",
            birthCountry: "",
            address: "",
            zipcode: "",
            city: "",
            country: "",
            phoneNumber: "",
            email: "",
            familySituation: "",
            ptz: null
        }
    ]);

    const [billingInputs, setBillingInputs] = useState({
        reference: "22-1234097",
        feasibilityStudy: null,
        outdoor3D: null,
        indoor3D: null,
        documentsToProvide: null,
        indoor2D: null,
        re2020: {exists: null, price: ""},
        price: "",
        paymentMode: null
    });


    ////////// Step 1 //////////
    const handleLandFormInputsChange = (event) => {
        const {name, value} = event.target;
        setLandFormInputs({...landFormInputs, [name]: value});
    }

    const handleSanitation = event => {
        const {name, value} = event.target;
        setLandFormInputs({...landFormInputs, sanitation: {...landFormInputs.sanitation, [name]: value}})
    }

    const handleCadastralReferenceInputs = (index, event) => {
        const {name, value} = event.target;
        let cadastralReferences = landFormInputs.cadastralReferences.slice();

        cadastralReferences[index] = {...cadastralReferences[index], [name]: value};
        setLandFormInputs({...landFormInputs, cadastralReferences: cadastralReferences});
    }

    const addCadastralReference = () => {
        let cadastralReferences = landFormInputs.cadastralReferences.slice();
        cadastralReferences.push({prefix: "000", section: "", number: ""});
        setLandFormInputs({...landFormInputs, cadastralReferences: cadastralReferences});
    }

    const deleteCadastralReference = i => {
        let cadastralReferences = landFormInputs.cadastralReferences.slice();
        setLandFormInputs({...landFormInputs, cadastralReferences: cadastralReferences.filter((v, index) => index !== i)});
    }

    const handleLotInputs = (index, event) => {
        let lots = landFormInputs.lots.slice();

        lots[index] = event.target.value;
        setLandFormInputs({...landFormInputs, lots: lots});
    }

    const addLot = () => {
        let lots = landFormInputs.lots.slice();
        lots.push("");
        setLandFormInputs({...landFormInputs, lots: lots});
    }

    const deleteLot = i => {
        let lots = landFormInputs.lots.slice();
        setLandFormInputs({ ...landFormInputs, lots: lots.filter((lot, index) => index !== i) });
    }

    const handleUrbanAuthorizationsInputs = (type, name, value) => {
        setLandFormInputs({...landFormInputs, [type]: {...landFormInputs[type], [name]: value}})
        console.log(landFormInputs)
    }

    const stepOne = () => {
        return (
            <LandForm
                inputs={landFormInputs}
                setInputs={setLandFormInputs}
                setInput={event => handleLandFormInputsChange(event)}
                addCadastralReference={addCadastralReference}
                deleteCadastralReference={deleteCadastralReference}
                handleCadastralReferenceInputs={(index, event) => handleCadastralReferenceInputs(index, event)}
                addLot={addLot}
                deleteLot={deleteLot}
                handleLotInputs={(index, event) => handleLotInputs(index, event)}
                handleSanitation={handleSanitation}
                handleUrbanAutorizationsInputs={handleUrbanAuthorizationsInputs}
            />
        );
    }

    ////////// Step 2 //////////

    const handleUrbanismRulesFormInputsChange = (event) => {
        const {name, value} = event.target;
        setUrbanismRulesFormInputs({...urbanismRulesFormInputs, [name]: value});
    }

    const handleIfExistsUrbanismRulesFormInputsChange = (event, fieldname) => {
        const {name, value} = event.target;
        setUrbanismRulesFormInputs({...urbanismRulesFormInputs, [name]: {...urbanismRulesFormInputs[name], [fieldname]: value}});
    }

    const handleCarpentriesIfExistsUrbanismRulesFormInputsChange = (event, fieldname) => {
        const {name, value} = event.target;
        const field = event.target.getAttribute("fieldname") || fieldname;
        setUrbanismRulesFormInputs(
        {
                ...urbanismRulesFormInputs,
                carpentries: {...urbanismRulesFormInputs.carpentries, [name]: {...urbanismRulesFormInputs.carpentries[name], [field]: value}}});
    }


    const handleConstructionPossibilitiesInputsChange = (event, type) => {
        const { name, value } = event.target;

        var constructionPossibilities = urbanismRulesFormInputs.constructionPossibilities.slice();
        var constructionPossibility = constructionPossibilities.find(o => o.type === (name === "type"? value : type));
        const numberOfLevelsPerType = {"R+CP": "1", "R+CA": "2", "R+x+CP": "", "R+x+CA": "", "R+TT": 1, "R+x+TT": ""}

        if(constructionPossibility && name === "type"){
            constructionPossibilities = constructionPossibilities.filter(o => o.type !== constructionPossibility.type);
        }
        else {
            constructionPossibility ?
                constructionPossibility[name] = value
                :
                constructionPossibilities = [...constructionPossibilities, {nbOfLevels: numberOfLevelsPerType[value], type: value, disabled: (numberOfLevelsPerType[value] !== "")}]
        }

        setUrbanismRulesFormInputs({...urbanismRulesFormInputs, constructionPossibilities: constructionPossibilities});
        console.log(urbanismRulesFormInputs.constructionPossibilities);
    }

    const handleCarpentriesArrayInput = event => {
        const {name, value} = event.target;
        let tmpArray = urbanismRulesFormInputs.carpentries[name].slice();
        tmpArray.indexOf(value) !== -1 ?
            tmpArray = tmpArray.filter(v => v !== value) :
            tmpArray.push(value);

        setUrbanismRulesFormInputs({
            ...urbanismRulesFormInputs,
            carpentries: {...urbanismRulesFormInputs.carpentries, [name]: tmpArray}
        });
        console.log(urbanismRulesFormInputs.carpentries)
    }

    const handleRoofingInput = event => {
        const {name, value} = event.target;
        setUrbanismRulesFormInputs(
            {
                ...urbanismRulesFormInputs,
                roofing: {...urbanismRulesFormInputs.roofing, [name]: value}
            });
        console.log(urbanismRulesFormInputs.roofing)
    }

    const handleRoofingArrayInput = event => {
        const {name, value} = event.target;
        let tmpArray = urbanismRulesFormInputs.roofing[name].slice();
        tmpArray.indexOf(value) !== -1 ?
            tmpArray = tmpArray.filter(v => v !== value) :
            tmpArray.push(value);

        setUrbanismRulesFormInputs({
            ...urbanismRulesFormInputs,
            roofing: {...urbanismRulesFormInputs.roofing, [name]: tmpArray}
        });
        console.log(urbanismRulesFormInputs.roofing)
    }

    const handleSubRoofingUrbanismRulesFormInputsChange = (event, fieldname) => {
        const {name, value} = event.target;
        const field = event.target.getAttribute("fieldname")  || fieldname;
        setUrbanismRulesFormInputs({...urbanismRulesFormInputs, roofing:{...urbanismRulesFormInputs.roofing, [name]: {...urbanismRulesFormInputs.roofing[name], [field]: value}}});
        console.log(urbanismRulesFormInputs.roofing)
    }

    const handleUrbanismInputObjectTab = (event, parentName) => {
        const {name, value} = event.target;
        let arrayTmp = urbanismRulesFormInputs[parentName][name].slice();
        arrayTmp.indexOf(value) === -1? arrayTmp.push(value) : arrayTmp = arrayTmp.filter(v => v !== value);
        setUrbanismRulesFormInputs({...urbanismRulesFormInputs, [parentName]: {...urbanismRulesFormInputs[parentName], [name]: arrayTmp}});
        console.log(urbanismRulesFormInputs);
    }

    const stepTwo = () => {
        return (
            <UrbanismRulesForm
                ref={componentRef}
                inputs={urbanismRulesFormInputs}
                setInput={handleUrbanismRulesFormInputsChange}
                handleConstructionPossibilities={handleConstructionPossibilitiesInputsChange}
                handleIfExistsInput={handleIfExistsUrbanismRulesFormInputsChange}
                handleCarpentriesIfExistsInput={handleCarpentriesIfExistsUrbanismRulesFormInputsChange}
                handleCarpentriesArrayInput={handleCarpentriesArrayInput}
                handleRoofingInput={handleRoofingInput}
                handleRoofingArrayInput={handleRoofingArrayInput}
                handleSubRoofingInput={handleSubRoofingUrbanismRulesFormInputsChange}
                area={landFormInputs.area}
                setInputObjectTab={handleUrbanismInputObjectTab}
            />
        )
    }

    ////////// Step 3 //////////
    const handleMaterialInputObject = (event, parentName) => {
        const {name, value} = event.target;
        setMaterialsFormInputs({...materialsFormInputs, [parentName]: {...materialsFormInputs[parentName], [name]: value}});
        console.log(materialsFormInputs);
    }

    const handleMaterialInputObjectTab = (event, parentName) => {
        const {name, value} = event.target;
        let arrayTmp = materialsFormInputs[parentName][name].slice();
        arrayTmp.indexOf(value) === -1? arrayTmp.push(value) : arrayTmp = arrayTmp.filter(v => v !== value);
        setMaterialsFormInputs({...materialsFormInputs, [parentName]: {...materialsFormInputs[parentName], [name]: arrayTmp}});
        console.log(materialsFormInputs);
    }

    const handleModenature = (event, location) => {
        const { name, value } = event.target;
        console.log(name, value)
        var modenatures = materialsFormInputs.modenatures.slice();

        var modenature = modenatures.find(o => o.location === (name === "location"? value : location));;

        if(modenature && name === "location"){
            modenatures = modenatures.filter(o => o.location !== modenature.location);
        }
        else {
            modenature ?
                modenature[name] = value
            :
                modenatures = [...modenatures, {type: "", color: "", location: value}]
        }
        setMaterialsFormInputs({...materialsFormInputs, modenatures: modenatures});
        console.log(materialsFormInputs.modenatures)
    }

    const handleShutter = (event, type) => {
        const { name, value } = event.target;

        var shutters = materialsFormInputs.shutters.slice();
        var shutter = shutters.find(o => o.type === (name === "type"? value : type));;

        if(shutter && name === "type"){
            shutters = shutters.filter(o => o.type !== shutter.type);
        }
        else {
            shutter ?
                shutter[name] = value
                :
                shutters = [...shutters, {material: "", color: "", type: value}]
        }
        setMaterialsFormInputs({...materialsFormInputs, shutters: shutters});
        console.log(materialsFormInputs.shutters)
    }

    const handleFences = (event, location, type) => {
        const { name, value } = event.target;

        var fences = materialsFormInputs[location].fences.slice();
        var fence = fences.find(o => o.type === (name === "type"? value : type));
        if(fence && name === "type"){
            fences = fences.filter(o => o.type !== fence.type);
        }
        else {
            fence ?
                fence[name] = value
                :
                fences = [...fences, {height: "", color: "", type: value}]
        }
        setMaterialsFormInputs({...materialsFormInputs, [location]: {...materialsFormInputs[location], fences: fences}});
        console.log(materialsFormInputs[location])
    }

    const handleType = (event, type, nbOfLevel) => {
        const { name, value } = event.target;

        var types = materialsFormInputs.types.slice();
        var type = types.find(o => o.type === (name === "type"? value : type));
        const numberOfLevelsPerType = {"R+CP": "1", "R+CA": "2", "R+x+CP": "", "R+x+CA": "", "R+TT": 1, "R+x+TT": ""}

        if(type && name === "type"){
            types = types.filter(o => o.type !== type.type);
        }
        else {
            type ?
                type[name] = value
                :
                types = [...types, {nbOfLevels: numberOfLevelsPerType[value], type: value, disabled: (numberOfLevelsPerType[value] !== "")}]
        }

        setMaterialsFormInputs({...materialsFormInputs, types: types});
        console.log(materialsFormInputs.types)
    }

    const handleRoof = (event, type) => {
        const { name, value } = event.target;

        var roofs = materialsFormInputs.roofs.slice();
        var roof = roofs.find(o => o.type === (name === "type"? value : type));;

        if(roof && name === "type"){
            roofs = roofs.filter(o => o.type !== roof.type);
        }
        else {
            roof ?
                roof[name] = value
                :
                roofs = [...roofs, {slope: "", m2unit: "", aspect: "", type: value}]
        }

        setMaterialsFormInputs({...materialsFormInputs, roofs: roofs});
        console.log(materialsFormInputs.roofs)
    }

    const handlePortal = (event, type) => {
        const { name, value } = event.target;

        var portals = materialsFormInputs.portals.slice();
        var portal = portals.find(o => o.type === (name === "type"? value : type));;

        if(portal && name === "type"){
            portals = portals.filter(o => o.type !== portal.type);
        }
        else {
            portal ?
                portal[name] = value
                :
                portals = [...portals, {type: value, material: "", color: "", width: "", height: ""}]
        }

        setMaterialsFormInputs({...materialsFormInputs, portals: portals});
        console.log(materialsFormInputs.portals)
    }

    const handleGate = (event, type) => {
        const { name, value } = event.target;

        var gates = materialsFormInputs.gates.slice();
        var gate = gates.find(o => o.type === (name === "type"? value : type));;

        if(gate && name === "type"){
            gates = gates.filter(o => o.type !== gate.type);
        }
        else {
            gate ?
                gate[name] = value
                :
                gates = [...gates, {type: value, material: "", color: "", width: "", height: ""}]
        }

        setMaterialsFormInputs({...materialsFormInputs, gates: gates});
        console.log(materialsFormInputs.gates)
    }

    const handleMaterialsFormInputsChange  = (event) => {
        const {name, value} = event.target;
        setMaterialsFormInputs({...materialsFormInputs, [name]: value})
    }

    const handleMaterialsFormInputsTabsChange  = (event) => {
        const {name, value} = event.target;
        let arrayTmp = materialsFormInputs[name].slice();
        arrayTmp.indexOf(value) === -1? arrayTmp.push(value) : arrayTmp = arrayTmp.filter(v => v !== value);
        setMaterialsFormInputs({...materialsFormInputs, [name]: arrayTmp});
        console.log(materialsFormInputs)
    }

    const stepThree = () => {
        return (
            <MaterialsForm
                inputs={materialsFormInputs}
                setInput={handleMaterialsFormInputsChange}
                setInputTab={handleMaterialsFormInputsTabsChange}
                setInputObject={handleMaterialInputObject}
                setInputObjectTab={handleMaterialInputObjectTab}
                handleModenature={handleModenature}
                handleShutter={handleShutter}
                handleRoof={handleRoof}
                handlePortal={handlePortal}
                handleGate={handleGate}
                handleFences={handleFences}
                handleType={handleType}
            />
        )
    }

    ////////// Step 4 //////////
    const handleApplicantFormInputsChange = (event, index) => {
        const {name, value} = event.target;
        let tmpArray = applicantsFormInputs.slice();
        tmpArray[index] = {...tmpArray[index], [name]: value}
        setApplicantsFormInputs(tmpArray);
        console.log(tmpArray)
    }

    const addApplicant = () => {
        setApplicantsFormInputs([
            ...applicantsFormInputs,
            {
                civility: "",
                firstname: "",
                lastname: "",
                birthdate: "",
                birthZipcode: "",
                birthCity: "",
                birthCountry: "",
                address: "",
                zipcode: "",
                city: "",
                country: "",
                phoneNumber: "",
                email: "",
                familySituation: ""
            }
        ]);
    }

    const deleteApplicant = i => {
        setApplicantsFormInputs(applicantsFormInputs.filter( (v, index) => index !== i));
    }

    const stepFour = () => {
        return (
            <PrincipalApplicantForm
                inputs={applicantsFormInputs}
                setInput={(event, index) => handleApplicantFormInputsChange(event, index)}
                addApplicant={addApplicant}
                deleteApplicant={deleteApplicant}
                className="mt-5"
            />
        )
    }

    ////////// Step 5 //////////
    const handleBillingInputObject = (event, parentName) => {
        const {name, value} = event.target;
        setBillingInputs({...billingInputs, [parentName]: {...billingInputs[parentName], [name]: value}});
        console.log(billingInputs);
    }

    const handleBillingInputsChange = event => {
        const {name, value} = event.target;
        setBillingInputs({...billingInputs, [name]: value})
    }

    const stepFive = () => {
        return (
            <BillingForm
                inputs={billingInputs}
                setInput={handleBillingInputsChange}
                setInputObject={handleBillingInputObject}
            />
        )
    }

    ////////// For all steps //////////
    const incrementStep = () => {
        if(currentStep+1 < steps.length) {
            window.scrollTo(0, 0)
            setCurrentStep(currentStep + 1);
        }
    }

    const decrementStep = () => {
        if(currentStep-1 >= 0)
            window.scrollTo(0, 0)
            setCurrentStep(currentStep -1);
    }

    const onSubmit = () => {
        return server.test(landFormInputs, urbanismRulesFormInputs, applicantsFormInputs, materialsFormInputs, billingInputs)
            .then(response => new Blob([response.data], {type: 'application/pdf'}))
            .then(pdfFile => window.open(URL.createObjectURL(pdfFile), '_blank'))
            /*.then(data => {
                const outputFilename = "terrain.xml";
                // If you want to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                link.click();
            });*/
    }

    const generateXml = () => {
        return server.generexml(landFormInputs, applicantsFormInputs, materialsFormInputs)
            .then(response => new Blob([response.data], {type: 'application/xml'}))
            .then(data => {
                const outputFilename = "informations_archicad_" + applicantsFormInputs[0].firstname + "_" + applicantsFormInputs[0].lastname + ".xml";
                // If you want to download file automatically using link attribute.
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', outputFilename);
                link.click();
            });
    }

    const steps = [
        {index: 0, title: "Terrain", number: 1, content: stepOne()},
        {index: 1, title: "Régles d'urbanisme", number: 2, content: stepTwo()},
        {index: 2, title: "Projet et matériaux", number: 3, content: stepThree()},
        {index: 3, title: "Client(s)", number: 4, content: stepFour()},
        {index: 4, title: "Facturation", number: 5, content: stepFive()}
    ]

    return(
        <>
            <Stepper currentStep={currentStep} setCurrentStep={step => setCurrentStep(step)} steps={steps} />
            {steps[currentStep].content}
            <Row>
                <Col>
                    { currentStep !== 0 && <button onClick={() => decrementStep()} className="bouton gradient">Précédent</button> }
                </Col>
                <Col className="d-flex justify-content-end">
                    { currentStep !== steps.length-1 && <button onClick={() => incrementStep()} className="bouton gradient">Suivant</button> }
                    {
                        currentStep === steps.length-1 && <button onClick={() => onSubmit()} className="bouton gradient">Génerer pdf</button>
                    }
                    {
                        currentStep === steps.length-1 && <button onClick={() => generateXml()} className="bouton gradient">Génerer xml</button>
                    }

                    {
                        currentStep === steps.length-1 &&
                        <div>
                            <button onClick={handlePrint} className="bouton gradient">Imprimer règles</button>
                            <div style={{display: 'none'}}>
                            <UrbanismRulesForm
                                ref={componentRef}
                                inputs={urbanismRulesFormInputs}
                                setInput={handleUrbanismRulesFormInputsChange}
                                handleConstructionPossibilities={handleConstructionPossibilitiesInputsChange}
                                handleIfExistsInput={handleIfExistsUrbanismRulesFormInputsChange}
                                handleCarpentriesIfExistsInput={handleCarpentriesIfExistsUrbanismRulesFormInputsChange}
                                handleCarpentriesArrayInput={handleCarpentriesArrayInput}
                                handleRoofingInput={handleRoofingInput}
                                handleRoofingArrayInput={handleRoofingArrayInput}
                                handleSubRoofingInput={handleSubRoofingUrbanismRulesFormInputsChange}
                                area={landFormInputs.area}
                                setInputObjectTab={handleUrbanismInputObjectTab}
                            />
                            </div>
                        </div>
                    }
                </Col>
            </Row>
        </>
    );
}
export default Steps;

/*
<MaterialsForm
                    inputs={materialsFormInputs}
                    setInputs={setLandFormInputs}
                    setInput={event => handleLandFormInputsChange(event)}
                    addLot={addCadastralReference}
                    handleCadastralReferenceInputs={(index, event) => handleCadastralReferenceInputs(index, event)}
                />
 */