import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import Text from "../../../../components/form/input/Text";
import Date from "../../../../components/form/input/Date";
import Select from "../../../../components/form/select/Select";
import Cross from "../../../../images/js/Cross"
import RadioChoice from "../../../../components/form/input/RadioChoice";

const PrincipalApplicantForm = props => {
    return(
        <>
            {
                props.inputs.map( (applicant, index) => {
                    return(
                        <Container key={"applicant-"+index} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                            <Row className="justify-content-center">
                                <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                                    <p className="font-monospace font-4">{"Demandeur " + (index+1)}</p>
                                </Col>
                                <Col sm={10} className="justify-content-center p-4 pb-5 position-relative">
                                    {(index > 0) && <Cross width={15} role="button" className="transform-scale-0 transform-scale-1 position-absolute top-0 end-0 mt-3 me-4" onClick={() => props.deleteApplicant(index)} /> }
                                    <Row>
                                        <Col>
                                            <Text value={props.inputs[index].lastname} label={"Nom"} name={"lastname"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                        <Col>
                                            <Text value={props.inputs[index].firstname} label={"Prénom"} name={"firstname"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Select label={"Civilité"} options={["Monsieur","Madame"]} name={"civility"} value={props.inputs[index].civility} onChange={e => props.setInput(e, index)} required />
                                        </Col>
                                        <Col>
                                            <Select label={"Situation familiale"} options={["Célibataire","Marié"]} name={"familySituation"} value={props.inputs[index].familySituation} onChange={e => props.setInput(e, index)} required />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7}>
                                            <Text value={props.inputs[index].email} label={"Email"} name={"email"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                        <Col sm={5}>
                                            <Text value={props.inputs[index].phoneNumber} label={"Numéro de téléphone"} name={"phoneNumber"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                    </Row>
                                    <div>
                                        <Row>
                                            <p className="mb-0 mt-4">Lieu de naissance</p>
                                            <Col sm={7}>
                                                <Date value={props.inputs[index].birthdate} label={"Date de naissance"} name={"birthdate"} onChange={e => props.setInput(e, index)} />
                                            </Col>
                                            <Col sm={5}>
                                                <Text value={props.inputs[index].birthZipcode} label={"Code postal"} name={"birthZipcode"} onChange={e => props.setInput(e, index)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text value={props.inputs[index].birthCity} label={"Ville"} name={"birthCity"} onChange={e => props.setInput(e, index)} />
                                            </Col>
                                            <Col>
                                                <Text value={props.inputs[index].birthCountry} label={"Pays"} name={"birthCountry"} onChange={e => props.setInput(e, index)} />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <p className="mt-4 mb-0">Lieu de résidence</p>
                                        <Col sm={7}>
                                            <Text value={props.inputs[index].address} label={"Adresse"} name={"address"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                        <Col sm={5}>
                                            <Text value={props.inputs[index].zipcode} label={"Code  postal"} name={"zipcode"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Text value={props.inputs[index].city} label={"Ville"} name={"city"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                        <Col >
                                            <Text value={props.inputs[index].country} label={"Pays"} name={"country"} onChange={e => props.setInput(e, index)} />
                                        </Col>
                                    </Row>
                                    { index === 0 &&
                                        <Row>
                                            <Col>
                                                <RadioChoice
                                                    legend={"Prêt à taux zéro (PTZ)"}
                                                    id={"ptz"}
                                                    name={"ptz"}
                                                    items={[
                                                        {label: "Non", value: "false", checked: props.inputs[index].ptz === "false"},
                                                        {label: "Oui", value: "true", checked: props.inputs[index].ptz === "true"}]
                                                    }
                                                    onChange={e => props.setInput(e, index)}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    );
                })
            }
            <Row>
                <div className="justify-content-center d-flex">
                    <button onClick={props.addApplicant} className="bouton gradient">+</button>
                </div>
            </Row>
        </>
    );
}

export default PrincipalApplicantForm;