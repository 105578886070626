import Text from "../../../../components/form/input/Text";
import RadioChoice from "../../../../components/form/input/RadioChoice";
import {
    Col,
    Container,
    Row
} from "react-bootstrap";

const LandForm = props => {
    return(
        <>
            <Container className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Coordonnées</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <Text name={"address"} label={"Adresse"} value={props.inputs.address} onChange={e => props.setInput(e)} />
                            </Col>
                            <Col>
                                <Text name={"zipcode"} label={"Code postal"} value={props.inputs.zipcode} onChange={e => props.setInput(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text name={"city"} label={"Ville"} value={props.inputs.city} onChange={e => props.setInput(e)} />
                            </Col>
                            <Col>
                                <Text name={"country"} label={"Pays"} value={props.inputs.country} onChange={e => props.setInput(e)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text name={"area"} label={"Superficie"} value={props.inputs.area} onChange={e => props.setInput(e)} />
                            </Col>
                        </Row>
                        <p className="mt-4 mb-0">Référence cadastrale</p>
                        {props.inputs.cadastralReferences.map((cadastralReference, i, tab) => {
                            return(
                                <Row key={"cadastralReference" + i}>
                                    <Col>
                                        <Text
                                            name={"section"}
                                            label={"Section"}
                                            value={cadastralReference.section}
                                            onChange={event => props.handleCadastralReferenceInputs(i,event)}
                                        />
                                    </Col>
                                    <Col>
                                        <Text
                                            name={"number"}
                                            label={"Numéro"}
                                            value={cadastralReference.number}
                                            onChange={event => props.handleCadastralReferenceInputs(i, event)}
                                        />
                                    </Col>
                                    {(tab.length > 1) && <button onClick={() => props.deleteCadastralReference(i)} className="bouton gradient">-</button>}
                                </Row>
                            );
                        })}
                        <Row>
                            <Col className={"d-flex justify-content-center"}>
                                <button onClick={props.addCadastralReference} className="bouton gradient ">+</button>
                            </Col>
                        </Row>
                        <p className="mt-4 mb-0">Lot</p>
                        {props.inputs.lots.map((lot, i) => {
                            return(
                                <Row key={"lot" + i}>
                                    <Col sm={10}>
                                        <Text
                                            name={"lot"}
                                            label={"Numéro du lot"}
                                            value={lot}
                                            onChange={event => props.handleLotInputs(i, event)}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <button onClick={() => props.deleteLot(i)} className="bouton gradient">-</button>
                                    </Col>
                                </Row>
                            );
                        })}
                        <Row>
                            <Col className={"d-flex justify-content-center"}>
                                <button onClick={props.addLot} className="bouton gradient">+</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Viabilisation</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <RadioChoice
                            id={"waterServicing"}
                            legend={"Eau"}
                            name={"waterServicing"}
                            items={[{label: "Existant", value: "false", checked: props.inputs.waterServicing === "false"}, {label: "A  créer", value: "true", checked: props.inputs.waterServicing === "true"}]}
                            onChange={props.setInput}
                        />
                        <RadioChoice
                            id={"electricityServicing"}
                            legend={"Electricité"}
                            name={"electricityServicing"}
                            items={[{label: "Existant", value: "false", checked: props.inputs.electricityServicing === "false"}, {label: "A  créer", value: "true", checked: props.inputs.electricityServicing === "true"}]}
                            onChange={props.setInput}
                        />
                        <RadioChoice
                            legend={"Eaux usées"}
                            id={"collectiveSanitation"}
                            value="collective"
                            name="type"
                            items={[{label: "Assainissement collectif (TAE)", value: "collective", checked: props.inputs.sanitation.type === "collective"}, {label: "Assainissement individuel", value: "individual", checked: props.inputs.sanitation.type === "individual"}]}
                            onChange={props.handleSanitation}
                        />

                        {
                            props.inputs.sanitation.type &&
                            <RadioChoice
                                id={"sanitationServicing"}
                                name={"servicing"}
                                items={[{label: "Existant", value: "false", checked: props.inputs.sanitation.servicing === "false"}, {label: "A  créer", value: "true",checked: props.inputs.sanitation.servicing === "true"}]}
                                onChange={props.handleSanitation}
                            />
                        }
                    </Col>
                </Row>
            </Container>
            <Container className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Autorisations d'urbanisme</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <RadioChoice
                            legend={"Certificat d'urbanisme (CU)"}
                            id={"cu"}
                            name={"cuPresence"}
                            items={[{label: "Non", value: "false",checked: props.inputs.cu.present === "false"}, {label: "Oui", value: "true", checked: props.inputs.cu.present === "true"}]}
                            onChange={e => props.handleUrbanAutorizationsInputs("cu", "present", e.target.value)}
                        />

                        {
                            props.inputs.cu.present === "true" &&
                            <>
                                <Text
                                    name={"authorizationNumber"}
                                    type={"text"}
                                    label={"Numéro d'autorisation"}
                                    value={props.inputs.cu.authorizationNumber}
                                    onChange={e => props.handleUrbanAutorizationsInputs("cu", e.target.name, e.target.value)}
                                />
                                <Text
                                    name={"deliveredDate"}
                                    type={"text"}
                                    label={"Date de délivrance"}
                                    value={props.inputs.cu.deliveredDate}
                                    onChange={e => props.handleUrbanAutorizationsInputs("cu", e.target.name, e.target.value)}
                                />
                            </>
                        }
                        <RadioChoice
                            legend={"Permis d'aménagé (PA)"}
                            id={"pa"}
                            name={"paPresence"}
                            items={[{label: "Non", value: "false",checked: props.inputs.pa.present === "false"}, {label: "Oui", value: "true", checked: props.inputs.pa.present === "true"}]}
                            onChange={e => props.handleUrbanAutorizationsInputs("pa", "present", e.target.value)}
                        />

                        {
                            props.inputs.pa.present === "true" &&
                            <>
                                <Text
                                    name={"authorizationNumber"}
                                    type={"text"}
                                    label={"Numéro d'autorisation"}
                                    value={props.inputs.pa.authorizationNumber}
                                    onChange={e => props.handleUrbanAutorizationsInputs("pa", e.target.name, e.target.value)}
                                />
                                <Text
                                    name={"deliveredDate"}
                                    type={"text"}
                                    label={"Date de délivrance"}
                                    value={props.inputs.pa.deliveredDate}
                                    onChange={e => props.handleUrbanAutorizationsInputs("pa", e.target.name, e.target.value)}
                                />
                            </>
                        }
                        <RadioChoice
                            legend={"Demande préalable de division (DP)"}
                            id={"dp"}
                            name={"dpPresence"}
                            items={[{label: "Non", value: "false",checked: props.inputs.dp.present === "false"}, {label: "Oui", value: "true", checked: props.inputs.dp.present === "true"}]}
                            onChange={e => props.handleUrbanAutorizationsInputs("dp", "present", e.target.value)}
                        />
                        {
                            props.inputs.dp.present === "true" &&
                            <>
                                <Text
                                    name={"authorizationNumber"}
                                    type={"text"}
                                    label={"Numéro d'autorisation"}
                                    value={props.inputs.dp.authorizationNumber}
                                    onChange={e => props.handleUrbanAutorizationsInputs("dp", e.target.name, e.target.value)}
                                />
                                <Text
                                    name={"deliveredDate"}
                                    type={"text"}
                                    label={"Date de délivrance"}
                                    value={props.inputs.dp.deliveredDate}
                                    onChange={e => props.handleUrbanAutorizationsInputs("dp", e.target.name, e.target.value)}
                                />
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default LandForm;