export const rals = [
    {
        "ralNumber": 1000,
        "FIELD2": "190-189-127",
        "color": "#BEBD7F",
        "colorName": "Beige vert"
    },
    {
        "ralNumber": 1001,
        "FIELD2": "194-176-120",
        "color": "#C2B078",
        "colorName": "Beige"
    },
    {
        "ralNumber": 1002,
        "FIELD2": "198-166-100",
        "color": "#C6A664",
        "colorName": "Jaune sable"
    },
    {
        "ralNumber": 1003,
        "FIELD2": "229-190-001",
        "color": "#E5BE01",
        "colorName": "Jaune de sécurité"
    },
    {
        "ralNumber": 1004,
        "FIELD2": "205-164-052",
        "color": "#CDA434",
        "colorName": "Jaune or"
    },
    {
        "ralNumber": 1005,
        "FIELD2": "169-131-007",
        "color": "#A98307",
        "colorName": "Jaune miel"
    },
    {
        "ralNumber": 1006,
        "FIELD2": "228-160-016",
        "color": "#E4A010",
        "colorName": "Jaune maïs"
    },
    {
        "ralNumber": 1007,
        "FIELD2": "220-156-000",
        "color": "#DC9D00",
        "colorName": "Jaune narcisse"
    },
    {
        "ralNumber": 1011,
        "FIELD2": "138-102-066",
        "color": "#8A6642",
        "colorName": "Beige brun"
    },
    {
        "ralNumber": 1012,
        "FIELD2": "199-180-070",
        "color": "#C7B446",
        "colorName": "Jaune citron"
    },
    {
        "ralNumber": 1013,
        "FIELD2": "234-230-202",
        "color": "#EAE6CA",
        "colorName": "Blanc perlé"
    },
    {
        "ralNumber": 1014,
        "FIELD2": "225-204-079",
        "color": "#E1CC4F",
        "colorName": "Ivoire"
    },
    {
        "ralNumber": 1015,
        "FIELD2": "230-214-144",
        "color": "#E6D690",
        "colorName": "Ivoire clair"
    },
    {
        "ralNumber": 1016,
        "FIELD2": "237-255-033",
        "color": "#EDFF21",
        "colorName": "Jaune soufre"
    },
    {
        "ralNumber": 1017,
        "FIELD2": "245-208-051",
        "color": "#F5D033",
        "colorName": "Jaune safran"
    },
    {
        "ralNumber": 1018,
        "FIELD2": "248-243-053",
        "color": "#F8F32B",
        "colorName": "Jaune zinc"
    },
    {
        "ralNumber": 1019,
        "FIELD2": "158-151-100",
        "color": "#9E9764",
        "colorName": "Beige gris"
    },
    {
        "ralNumber": 1020,
        "FIELD2": "153-153-080",
        "color": "#999950",
        "colorName": "Jaune olive"
    },
    {
        "ralNumber": 1021,
        "FIELD2": "243-218-011",
        "color": "#F3DA0B",
        "colorName": "Jaune colza"
    },
    {
        "ralNumber": 1023,
        "FIELD2": "250-210-001",
        "color": "#FAD201",
        "colorName": "Jaune signalisation"
    },
    {
        "ralNumber": 1024,
        "FIELD2": "174-160-075",
        "color": "#AEA04B",
        "colorName": "Jaune ocre"
    },
    {
        "ralNumber": 1026,
        "FIELD2": "255-255-000",
        "color": "#FFFF00",
        "colorName": "Jaune brillant"
    },
    {
        "ralNumber": 1027,
        "FIELD2": "157-145-001",
        "color": "#9D9101",
        "colorName": "Jaune curry"
    },
    {
        "ralNumber": 1028,
        "FIELD2": "244-169-000",
        "color": "#F4A900",
        "colorName": "Jaune melon"
    },
    {
        "ralNumber": 1032,
        "FIELD2": "214-174-001",
        "color": "#D6AE01",
        "colorName": "Jaune genêt"
    },
    {
        "ralNumber": 1033,
        "FIELD2": "243-165-005",
        "color": "#F3A505",
        "colorName": "Jaune dahlia"
    },
    {
        "ralNumber": 1034,
        "FIELD2": "239-169-074",
        "color": "#EFA94A",
        "colorName": "Jaune pastel"
    },
    {
        "ralNumber": 1035,
        "FIELD2": "106-093-077",
        "color": "#6A5D4D",
        "colorName": "Beige nacré"
    },
    {
        "ralNumber": 1036,
        "FIELD2": "112-083-053",
        "color": "#705335",
        "colorName": "Or nacré"
    },
    {
        "ralNumber": 1037,
        "FIELD2": "243-159-024",
        "color": "#F39F18",
        "colorName": "Jaune soleil"
    },
    {
        "ralNumber": 2000,
        "FIELD2": "237-118-014",
        "color": "#ED760E",
        "colorName": "Orangé jaune"
    },
    {
        "ralNumber": 2001,
        "FIELD2": "201-060-032",
        "color": "#C93C20",
        "colorName": "Orangé rouge"
    },
    {
        "ralNumber": 2002,
        "FIELD2": "203-040-033",
        "color": "#CB2821",
        "colorName": "Orangé sang"
    },
    {
        "ralNumber": 2003,
        "FIELD2": "255-117-020",
        "color": "#FF7514",
        "colorName": "Orangé pastel"
    },
    {
        "ralNumber": 2004,
        "FIELD2": "244-070-017",
        "color": "#F44611",
        "colorName": "Orangé pur"
    },
    {
        "ralNumber": 2005,
        "FIELD2": "255-035-001",
        "color": "#FF2301",
        "colorName": "Orangé brillant"
    },
    {
        "ralNumber": 2007,
        "FIELD2": "255-164-032",
        "color": "#FFA420",
        "colorName": "Orangé clair rillant"
    },
    {
        "ralNumber": 2008,
        "FIELD2": "247-094-037",
        "color": "#F75E25",
        "colorName": "Orangé rouge clair"
    },
    {
        "ralNumber": 2009,
        "FIELD2": "245-064-033",
        "color": "#F54021",
        "colorName": "Orangé signalisation"
    },
    {
        "ralNumber": 2010,
        "FIELD2": "216-075-032",
        "color": "#D84B20",
        "colorName": "Orangé de sécurité"
    },
    {
        "ralNumber": 2011,
        "FIELD2": "236-124-038",
        "color": "#EC7C26",
        "colorName": "Orangé foncé"
    },
    {
        "ralNumber": 2012,
        "FIELD2": "235-106-014",
        "color": "#E55137",
        "colorName": "Orangé saumon"
    },
    {
        "ralNumber": 2013,
        "FIELD2": "195-088-049",
        "color": "#C35831",
        "colorName": "Orangé nacré"
    },
    {
        "ralNumber": 3000,
        "FIELD2": "175-043-030",
        "color": "#AF2B1E",
        "colorName": "Rouge feu"
    },
    {
        "ralNumber": 3001,
        "FIELD2": "165-032-025",
        "color": "#A52019",
        "colorName": "Rouge de sécurité"
    },
    {
        "ralNumber": 3002,
        "FIELD2": "162-035-029",
        "color": "#A2231D",
        "colorName": "Rouge carmin"
    },
    {
        "ralNumber": 3003,
        "FIELD2": "155-017-030",
        "color": "#9B111E",
        "colorName": "Rouge rubis"
    },
    {
        "ralNumber": 3004,
        "FIELD2": "117-021-030",
        "color": "#75151E",
        "colorName": "Rouge pourpre"
    },
    {
        "ralNumber": 3005,
        "FIELD2": "094-033-041",
        "color": "#5E2129",
        "colorName": "Rouge vin"
    },
    {
        "ralNumber": 3007,
        "FIELD2": "065-034-039",
        "color": "#412227",
        "colorName": "Rouge noir"
    },
    {
        "ralNumber": 3009,
        "FIELD2": "100-036-036",
        "color": "#642424",
        "colorName": "Rouge oxyde"
    },
    {
        "ralNumber": 3011,
        "FIELD2": "120-031-025",
        "color": "#781F19",
        "colorName": "Rouge brun"
    },
    {
        "ralNumber": 3012,
        "FIELD2": "193-135-107",
        "color": "#C1876B",
        "colorName": "Rouge beige"
    },
    {
        "ralNumber": 3013,
        "FIELD2": "161-035-018",
        "color": "#A12312",
        "colorName": "Rouge tomate"
    },
    {
        "ralNumber": 3014,
        "FIELD2": "211-110-112",
        "color": "#D36E70",
        "colorName": "Vieux rose"
    },
    {
        "ralNumber": 3015,
        "FIELD2": "234-137-154",
        "color": "#EA899A",
        "colorName": "Rose clair"
    },
    {
        "ralNumber": 3016,
        "FIELD2": "179-040-033",
        "color": "#B32821",
        "colorName": "Rouge corail"
    },
    {
        "ralNumber": 3017,
        "FIELD2": "230-050-068",
        "color": "#E63244",
        "colorName": "Rosé"
    },
    {
        "ralNumber": 3018,
        "FIELD2": "213-048-050",
        "color": "#D53032",
        "colorName": "Rouge fraise"
    },
    {
        "ralNumber": 3020,
        "FIELD2": "204-006-005",
        "color": "#CC0605",
        "colorName": "Rouge signalisation"
    },
    {
        "ralNumber": 3022,
        "FIELD2": "217-080-048",
        "color": "#D95030",
        "colorName": "Rouge saumon"
    },
    {
        "ralNumber": 3024,
        "FIELD2": "248-000-000",
        "color": "#F80000",
        "colorName": "Rouge brillant"
    },
    {
        "ralNumber": 3026,
        "FIELD2": "254-000-000",
        "color": "#FE0000",
        "colorName": "Rouge clair brillant"
    },
    {
        "ralNumber": 3027,
        "FIELD2": "197-029-052",
        "color": "#C51D34",
        "colorName": "Rouge framboise"
    },
    {
        "ralNumber": 3028,
        "FIELD2": "231-037-018",
        "color": "#E72512",
        "colorName": "Rouge puro"
    },
    {
        "ralNumber": 3031,
        "FIELD2": "179-036-040",
        "color": "#B32428",
        "colorName": "Rouge oriental"
    },
    {
        "ralNumber": 3032,
        "FIELD2": "114-020-034",
        "color": "#721422",
        "colorName": "Rouge rubis nacré"
    },
    {
        "ralNumber": 3033,
        "FIELD2": "180-076-067",
        "color": "#B44C43",
        "colorName": "Rose nacré"
    },
    {
        "ralNumber": 4001,
        "FIELD2": "222-076-138",
        "color": "#6D3F5B",
        "colorName": "Lilas rouge"
    },
    {
        "ralNumber": 4002,
        "FIELD2": "146-043-062",
        "color": "#922B3E",
        "colorName": "Violet rouge"
    },
    {
        "ralNumber": 4003,
        "FIELD2": "222-076-138",
        "color": "#DE4C8A",
        "colorName": "Violet bruyère"
    },
    {
        "ralNumber": 4004,
        "FIELD2": "110-028-052",
        "color": "#641C34",
        "colorName": "Violet bordeaux"
    },
    {
        "ralNumber": 4005,
        "FIELD2": "108-070-117",
        "color": "#6C4675",
        "colorName": "Lilas bleu"
    },
    {
        "ralNumber": 4006,
        "FIELD2": "160-052-114",
        "color": "#A03472",
        "colorName": "Pourpre signalisation"
    },
    {
        "ralNumber": 4007,
        "FIELD2": "074-025-044",
        "color": "#4A192C",
        "colorName": "Violet pourpre"
    },
    {
        "ralNumber": 4008,
        "FIELD2": "140-086-138",
        "color": "#8C568A",
        "colorName": "Violet de sécurité"
    },
    {
        "ralNumber": 4009,
        "FIELD2": "164-125-144",
        "color": "#A18594",
        "colorName": "Violet pastel"
    },
    {
        "ralNumber": 4010,
        "FIELD2": "215-045-109",
        "color": "#CF3476",
        "colorName": "Telemagenta"
    },
    {
        "ralNumber": 4011,
        "FIELD2": "134-115-161",
        "color": "#8673A1",
        "colorName": "Violet nacré"
    },
    {
        "ralNumber": 4012,
        "FIELD2": "108-104-129",
        "color": "#6C6874",
        "colorName": "Mûre nacré"
    },
    {
        "ralNumber": 5000,
        "FIELD2": "042-046-075",
        "color": "#354D73",
        "colorName": "Bleu violet"
    },
    {
        "ralNumber": 5001,
        "FIELD2": "031-052-056",
        "color": "#1F3438",
        "colorName": "Bleu vert"
    },
    {
        "ralNumber": 5002,
        "FIELD2": "032-033-079",
        "color": "#20214F",
        "colorName": "Bleu outremer"
    },
    {
        "ralNumber": 5003,
        "FIELD2": "029-030-051",
        "color": "#1D1E33",
        "colorName": "Bleu saphir"
    },
    {
        "ralNumber": 5004,
        "FIELD2": "032-033-079",
        "color": "#18171C",
        "colorName": "Bleu noir"
    },
    {
        "ralNumber": 5005,
        "FIELD2": "030-045-110",
        "color": "#1E2460",
        "colorName": "Bleu de sécurité"
    },
    {
        "ralNumber": 5007,
        "FIELD2": "062-095-138",
        "color": "#3E5F8A",
        "colorName": "Bleu brillant"
    },
    {
        "ralNumber": 5008,
        "FIELD2": "038-037-045",
        "color": "#26252D",
        "colorName": "Bleu gris"
    },
    {
        "ralNumber": 5009,
        "FIELD2": "002-086-105",
        "color": "#025669",
        "colorName": "Bleu azur"
    },
    {
        "ralNumber": 5010,
        "FIELD2": "014-041-075",
        "color": "#0E294B",
        "colorName": "Bleu gentiane"
    },
    {
        "ralNumber": 5011,
        "FIELD2": "035-026-036",
        "color": "#231A24",
        "colorName": "Bleu acier"
    },
    {
        "ralNumber": 5012,
        "FIELD2": "059-131-189",
        "color": "#3B83BD",
        "colorName": "Bleu clair"
    },
    {
        "ralNumber": 5013,
        "FIELD2": "037-041-074",
        "color": "#1E213D",
        "colorName": "Bleu cobalt"
    },
    {
        "ralNumber": 5014,
        "FIELD2": "096-111-140",
        "color": "#606E8C",
        "colorName": "Bleu pigeon"
    },
    {
        "ralNumber": 5015,
        "FIELD2": "034-113-179",
        "color": "#2271B3",
        "colorName": "Bleu ciel"
    },
    {
        "ralNumber": 5017,
        "FIELD2": "006-057-113",
        "color": "#063971",
        "colorName": "Bleu signalisation"
    },
    {
        "ralNumber": 5018,
        "FIELD2": "063-136-143",
        "color": "#3F888F",
        "colorName": "Bleu turquoise"
    },
    {
        "ralNumber": 5019,
        "FIELD2": "027-085-131",
        "color": "#1B5583",
        "colorName": "Bleu capri"
    },
    {
        "ralNumber": 5020,
        "FIELD2": "029-051-074",
        "color": "#1D334A",
        "colorName": "Bleu océan"
    },
    {
        "ralNumber": 5021,
        "FIELD2": "037-109-123",
        "color": "#256D7B",
        "colorName": "Bleu deau"
    },
    {
        "ralNumber": 5022,
        "FIELD2": "037-040-080",
        "color": "#252850",
        "colorName": "Bleu nocturne"
    },
    {
        "ralNumber": 5023,
        "FIELD2": "073-103-141",
        "color": "#49678D",
        "colorName": "Bleu distant"
    },
    {
        "ralNumber": 5024,
        "FIELD2": "093-155-155",
        "color": "#5D9B9B",
        "colorName": "Bleu pastel"
    },
    {
        "ralNumber": 5025,
        "FIELD2": "042-100-120",
        "color": "#2A6478",
        "colorName": "Gentiane nacré"
    },
    {
        "ralNumber": 5026,
        "FIELD2": "016-044-084",
        "color": "#102C54",
        "colorName": "Bleu nuit nacré"
    },
    {
        "ralNumber": 6000,
        "FIELD2": "049-102-080",
        "color": "#316650",
        "colorName": "Vert patine"
    },
    {
        "ralNumber": 6001,
        "FIELD2": "040-114-051",
        "color": "#287233",
        "colorName": "Vert émeraude"
    },
    {
        "ralNumber": 6002,
        "FIELD2": "045-087-044",
        "color": "#2D572C",
        "colorName": "Vert feuillage"
    },
    {
        "ralNumber": 6003,
        "FIELD2": "066-070-050",
        "color": "#424632",
        "colorName": "Vert olive"
    },
    {
        "ralNumber": 6004,
        "FIELD2": "031-058-061",
        "color": "#1F3A3D",
        "colorName": "Vert bleu"
    },
    {
        "ralNumber": 6005,
        "FIELD2": "047-069-056",
        "color": "#2F4538",
        "colorName": "Vert mousse"
    },
    {
        "ralNumber": 6006,
        "FIELD2": "062-059-050",
        "color": "#3E3B32",
        "colorName": "Olive gris"
    },
    {
        "ralNumber": 6007,
        "FIELD2": "052-059-041",
        "color": "#343B29",
        "colorName": "Vert bouteille"
    },
    {
        "ralNumber": 6008,
        "FIELD2": "057-053-042",
        "color": "#39352A",
        "colorName": "Vert brun"
    },
    {
        "ralNumber": 6009,
        "FIELD2": "049-055-043",
        "color": "#31372B",
        "colorName": "Vert sapin"
    },
    {
        "ralNumber": 6010,
        "FIELD2": "053-104-045",
        "color": "#35682D",
        "colorName": "Vert herbe"
    },
    {
        "ralNumber": 6011,
        "FIELD2": "088-114-070",
        "color": "#587246",
        "colorName": "Vert réséda"
    },
    {
        "ralNumber": 6012,
        "FIELD2": "052-062-064",
        "color": "#343E40",
        "colorName": "Vert noir"
    },
    {
        "ralNumber": 6013,
        "FIELD2": "108-113-086",
        "color": "#6C7156",
        "colorName": "Vert jonc"
    },
    {
        "ralNumber": 6014,
        "FIELD2": "071-064-046",
        "color": "#47402E",
        "colorName": "Olive jaune"
    },
    {
        "ralNumber": 6015,
        "FIELD2": "059-060-054",
        "color": "#3B3C36",
        "colorName": "Olive noir"
    },
    {
        "ralNumber": 6016,
        "FIELD2": "030-089-069",
        "color": "#1E5945",
        "colorName": "Vert turquoise"
    },
    {
        "ralNumber": 6017,
        "FIELD2": "076-145-065",
        "color": "#4C9141",
        "colorName": "Vert mai"
    },
    {
        "ralNumber": 6018,
        "FIELD2": "087-166-057",
        "color": "#57A639",
        "colorName": "Vert jaune"
    },
    {
        "ralNumber": 6019,
        "FIELD2": "189-236-182",
        "color": "#BDECB6",
        "colorName": "Vert blanc"
    },
    {
        "ralNumber": 6020,
        "FIELD2": "046-058-035",
        "color": "#2E3A23",
        "colorName": "Vert oxyde chromique"
    },
    {
        "ralNumber": 6021,
        "FIELD2": "137-172-118",
        "color": "#89AC76",
        "colorName": "Vert pâle"
    },
    {
        "ralNumber": 6022,
        "FIELD2": "037-034-027",
        "color": "#25221B",
        "colorName": "Olive brun"
    },
    {
        "ralNumber": 6024,
        "FIELD2": "048-132-070",
        "color": "#308446",
        "colorName": "Vert signalisation"
    },
    {
        "ralNumber": 6025,
        "FIELD2": "061-100-045",
        "color": "#3D642D",
        "colorName": "Vert fougère"
    },
    {
        "ralNumber": 6026,
        "FIELD2": "001-093-082",
        "color": "#015D52",
        "colorName": "Vert opale"
    },
    {
        "ralNumber": 6027,
        "FIELD2": "132-195-190",
        "color": "#84C3BE",
        "colorName": "Vert clair"
    },
    {
        "ralNumber": 6028,
        "FIELD2": "044-085-069",
        "color": "#2C5545",
        "colorName": "Vert pin"
    },
    {
        "ralNumber": 6029,
        "FIELD2": "032-096-061",
        "color": "#20603D",
        "colorName": "Vert menthe"
    },
    {
        "ralNumber": 6032,
        "FIELD2": "049-127-067",
        "color": "#317F43",
        "colorName": "Vert de sécurité"
    },
    {
        "ralNumber": 6033,
        "FIELD2": "073-126-118",
        "color": "#497E76",
        "colorName": "Turquoise menthe"
    },
    {
        "ralNumber": 6034,
        "FIELD2": "127-181-181",
        "color": "#7FB5B5",
        "colorName": "Turquoise pastel"
    },
    {
        "ralNumber": 6035,
        "FIELD2": "028-084-045",
        "color": "#1C542D",
        "colorName": "Vert nacré"
    },
    {
        "ralNumber": 6036,
        "FIELD2": "022-053-055",
        "color": "#193737",
        "colorName": "Vert opal nacré"
    },
    {
        "ralNumber": 6037,
        "FIELD2": "036-231-017",
        "color": "#24E711",
        "colorName": "Vert pur"
    },
    {
        "ralNumber": 6038,
        "FIELD2": "000-247-000",
        "color": "#00F700",
        "colorName": "Vert brillant"
    },
    {
        "ralNumber": 7000,
        "FIELD2": "120-133-139",
        "color": "#78858B",
        "colorName": "Gris petit-gris"
    },
    {
        "ralNumber": 7001,
        "FIELD2": "138-149-151",
        "color": "#8A9597",
        "colorName": "Gris argent"
    },
    {
        "ralNumber": 7002,
        "FIELD2": "126-123-082",
        "color": "#7E7B52",
        "colorName": "Gris olive"
    },
    {
        "ralNumber": 7003,
        "FIELD2": "108-112-089",
        "color": "#6C7059",
        "colorName": "Gris mousse"
    },
    {
        "ralNumber": 7004,
        "FIELD2": "150-153-146",
        "color": "#969992",
        "colorName": "Gris de sécurité"
    },
    {
        "ralNumber": 7005,
        "FIELD2": "100-107-099",
        "color": "#646B63",
        "colorName": "Gris souris"
    },
    {
        "ralNumber": 7006,
        "FIELD2": "109-101-082",
        "color": "#6D6552",
        "colorName": "Gris beige"
    },
    {
        "ralNumber": 7008,
        "FIELD2": "106-095-049",
        "color": "#6A5F31",
        "colorName": "Gris kaki"
    },
    {
        "ralNumber": 7009,
        "FIELD2": "077-086-069",
        "color": "#4D5645",
        "colorName": "Gris vert"
    },
    {
        "ralNumber": 7010,
        "FIELD2": "076-081-074",
        "color": "#4C514A",
        "colorName": "Gris tente"
    },
    {
        "ralNumber": 7011,
        "FIELD2": "067-075-077",
        "color": "#434B4D",
        "colorName": "Gris fer"
    },
    {
        "ralNumber": 7012,
        "FIELD2": "078-087-084",
        "color": "#4E5754",
        "colorName": "Gris basalte"
    },
    {
        "ralNumber": 7013,
        "FIELD2": "070-069-049",
        "color": "#464531",
        "colorName": "Gris brun"
    },
    {
        "ralNumber": 7015,
        "FIELD2": "067-071-080",
        "color": "#434750",
        "colorName": "Gris ardoise"
    },
    {
        "ralNumber": 7016,
        "FIELD2": "041-049-051",
        "color": "#293133",
        "colorName": "Gris anthracite"
    },
    {
        "ralNumber": 7021,
        "FIELD2": "035-040-043",
        "color": "#23282B",
        "colorName": "Gris noir"
    },
    {
        "ralNumber": 7022,
        "FIELD2": "051-047-044",
        "color": "#332F2C",
        "colorName": "Gris terre dombre"
    },
    {
        "ralNumber": 7023,
        "FIELD2": "104-108-094",
        "color": "#686C5E",
        "colorName": "Gris béton"
    },
    {
        "ralNumber": 7024,
        "FIELD2": "071-074-081",
        "color": "#474A51",
        "colorName": "Gris graphite"
    },
    {
        "ralNumber": 7026,
        "FIELD2": "047-053-059",
        "color": "#2F353B",
        "colorName": "Gris granit"
    },
    {
        "ralNumber": 7030,
        "FIELD2": "139-140-122",
        "color": "#8B8C7A",
        "colorName": "Gris pierre"
    },
    {
        "ralNumber": 7031,
        "FIELD2": "071-075-078",
        "color": "#474B4E",
        "colorName": "Gris bleu"
    },
    {
        "ralNumber": 7032,
        "FIELD2": "184-183-153",
        "color": "#B8B799",
        "colorName": "Gris silex"
    },
    {
        "ralNumber": 7033,
        "FIELD2": "125-132-113",
        "color": "#7D8471",
        "colorName": "Gris ciment"
    },
    {
        "ralNumber": 7034,
        "FIELD2": "143-139-102",
        "color": "#8F8B66",
        "colorName": "Gris jaune"
    },
    {
        "ralNumber": 7035,
        "FIELD2": "215-215-215",
        "color": "#D7D7D7",
        "colorName": "Gris clair"
    },
    {
        "ralNumber": 7036,
        "FIELD2": "127-118-121",
        "color": "#7F7679",
        "colorName": "Gris platine"
    },
    {
        "ralNumber": 7037,
        "FIELD2": "125-127-120",
        "color": "#7D7F7D",
        "colorName": "Gris poussière"
    },
    {
        "ralNumber": 7038,
        "FIELD2": "195-195-195",
        "color": "#B5B8B1",
        "colorName": "Gris agate"
    },
    {
        "ralNumber": 7039,
        "FIELD2": "108-105-096",
        "color": "#6C6960",
        "colorName": "Gris quartz"
    },
    {
        "ralNumber": 7040,
        "FIELD2": "157-161-170",
        "color": "#9DA1AA",
        "colorName": "Gris fenêtre"
    },
    {
        "ralNumber": 7042,
        "FIELD2": "141-148-141",
        "color": "#8D948D",
        "colorName": "Gris signalisation A"
    },
    {
        "ralNumber": 7043,
        "FIELD2": "078-084-082",
        "color": "#4E5452",
        "colorName": "Gris signalisation B"
    },
    {
        "ralNumber": 7044,
        "FIELD2": "202-196-176",
        "color": "#CAC4B0",
        "colorName": "Gris soie"
    },
    {
        "ralNumber": 7045,
        "FIELD2": "144-144-144",
        "color": "#909090",
        "colorName": "Telegris 1"
    },
    {
        "ralNumber": 7046,
        "FIELD2": "130-137-143",
        "color": "#82898F",
        "colorName": "Telegris 2"
    },
    {
        "ralNumber": 7047,
        "FIELD2": "208-208-208",
        "color": "#D0D0D0",
        "colorName": "Telegris 4"
    },
    {
        "ralNumber": 7048,
        "FIELD2": "137-129-118",
        "color": "#898176",
        "colorName": "Gris souris nacré"
    },
    {
        "ralNumber": 8000,
        "FIELD2": "130-108-052",
        "color": "#826C34",
        "colorName": "Brun vert"
    },
    {
        "ralNumber": 8001,
        "FIELD2": "149-095-032",
        "color": "#955F20",
        "colorName": "Brun terre de Sienne"
    },
    {
        "ralNumber": 8002,
        "FIELD2": "108-059-042",
        "color": "#6C3B2A",
        "colorName": "Brun de sécurité"
    },
    {
        "ralNumber": 8003,
        "FIELD2": "115-066-034",
        "color": "#734222",
        "colorName": "Brun argile"
    },
    {
        "ralNumber": 8004,
        "FIELD2": "142-064-042",
        "color": "#8E402A",
        "colorName": "Brun cuivré"
    },
    {
        "ralNumber": 8007,
        "FIELD2": "089-053-031",
        "color": "#59351F",
        "colorName": "Brun fauve"
    },
    {
        "ralNumber": 8008,
        "FIELD2": "111-079-040",
        "color": "#6F4F28",
        "colorName": "Brun olive"
    },
    {
        "ralNumber": 8011,
        "FIELD2": "091-058-041",
        "color": "#5B3A29",
        "colorName": "Brun noisette"
    },
    {
        "ralNumber": 8012,
        "FIELD2": "089-035-033",
        "color": "#592321",
        "colorName": "Brun rouge"
    },
    {
        "ralNumber": 8014,
        "FIELD2": "056-044-030",
        "color": "#382C1E",
        "colorName": "Brun sépia"
    },
    {
        "ralNumber": 8015,
        "FIELD2": "099-058-052",
        "color": "#633A34",
        "colorName": "Marron"
    },
    {
        "ralNumber": 8016,
        "FIELD2": "076-047-039",
        "color": "#4C2F27",
        "colorName": "Brun acajou"
    },
    {
        "ralNumber": 8017,
        "FIELD2": "069-050-046",
        "color": "#45322E",
        "colorName": "Brun chocolat"
    },
    {
        "ralNumber": 8019,
        "FIELD2": "064-058-058",
        "color": "#403A3A",
        "colorName": "Brun gris"
    },
    {
        "ralNumber": 8022,
        "FIELD2": "033-033-033",
        "color": "#212121",
        "colorName": "Brun noir"
    },
    {
        "ralNumber": 8023,
        "FIELD2": "166-094-046",
        "color": "#A65E2E",
        "colorName": "Brun orangé"
    },
    {
        "ralNumber": 8024,
        "FIELD2": "121-085-061",
        "color": "#79553D",
        "colorName": "Brun beige"
    },
    {
        "ralNumber": 8025,
        "FIELD2": "117-092-072",
        "color": "#755C48",
        "colorName": "Brun pâle"
    },
    {
        "ralNumber": 8028,
        "FIELD2": "078-059-049",
        "color": "#4E3B31",
        "colorName": "Brun terre"
    },
    {
        "ralNumber": 8029,
        "FIELD2": "118-060-040",
        "color": "#763C28",
        "colorName": "Cuivre nacré"
    },
    {
        "ralNumber": 9001,
        "FIELD2": "250-244-227",
        "color": "#FDF4E3",
        "colorName": "Blanc crème"
    },
    {
        "ralNumber": 9002,
        "FIELD2": "231-235-218",
        "color": "#E7EBDA",
        "colorName": "Blanc gris"
    },
    {
        "ralNumber": 9003,
        "FIELD2": "244-244-244",
        "color": "#F4F4F4",
        "colorName": "Blanc de sécurité"
    },
    {
        "ralNumber": 9004,
        "FIELD2": "040-040-040",
        "color": "#282828",
        "colorName": "Noir de sécurité"
    },
    {
        "ralNumber": 9005,
        "FIELD2": "010-010-010",
        "color": "#0A0A0A",
        "colorName": "Noir foncé"
    },
    {
        "ralNumber": 9006,
        "FIELD2": "165-165-165",
        "color": "#A5A5A5",
        "colorName": "Aluminium blanc"
    },
    {
        "ralNumber": 9007,
        "FIELD2": "143-143-143",
        "color": "#8F8F8F",
        "colorName": "Aluminium gris"
    },
    {
        "ralNumber": 9010,
        "FIELD2": "255-255-255",
        "color": "#FFFFFF",
        "colorName": "Blanc pur"
    },
    {
        "ralNumber": 9011,
        "FIELD2": "028-028-028",
        "color": "#1C1C1C",
        "colorName": "Noir graphite"
    },
    {
        "ralNumber": 9016,
        "FIELD2": "246-246-246",
        "color": "#F6F6F6",
        "colorName": "Blanc signalisation"
    },
    {
        "ralNumber": 9017,
        "FIELD2": "030-030-030",
        "color": "#1E1E1E",
        "colorName": "Noir signalisation"
    },
    {
        "ralNumber": 9018,
        "FIELD2": "215-215-215",
        "color": "#D7D7D7",
        "colorName": "Blanc papyrus"
    },
    {
        "ralNumber": 9022,
        "FIELD2": "156-156-156",
        "color": "#9C9C9C",
        "colorName": "Gris clair nacré"
    },
    {
        "ralNumber": 9023,
        "FIELD2": "130-130-130",
        "color": "#828282",
        "colorName": "Gris fonçé nacré"
    }
]