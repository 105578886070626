import Steps from "./steps/Steps";
import {Container} from "react-bootstrap";

const NewProject = props => {
    return(
        <Container className={"mt-lg-5 mb-lg-5 kk"}>
            <Steps />
        </Container>
    );
}

export default NewProject;