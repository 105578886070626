import Text from "../../../../components/form/input/Text";
import React, {forwardRef} from "react";
import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import CheckboxChoice from "../../../../components/form/input/CheckboxChoice";

import r_cp from "../../../../images/svg/types/r_cp.svg";
import r_ca from "../../../../images/svg/types/r_ca.svg";
import r_tt from "../../../../images/svg/types/r_tt.svg";
import rx_cp from "../../../../images/svg/types/r_x_cp.svg";
import rx_ca from "../../../../images/svg/types/r_x_ca.svg";
import rx_tt from "../../../../images/svg/types/r_x_tt.svg";


import garage from "../../../../images/svg/annexes/garage.svg";
import abri from "../../../../images/svg/annexes/abri_de_jardin.svg";
import veranda from "../../../../images/svg/annexes/veranda.svg";
import pool from "../../../../images/svg/annexes/pool.svg";

import terre_plein from "../../../../images/svg/fondations/terre_plein.svg";
import vide_sanitaire from "../../../../images/svg/fondations/vide_sanitaire.svg";
import cave from "../../../../images/svg/fondations/cave.svg";
import sous_sol from "../../../../images/svg/fondations/sous_sol.svg";

import autour_des_menuiseries from "../../../../images/svg/modenatures/autour_des_menuiseries.svg";
import autour_du_pignon from "../../../../images/svg/modenatures/autour_du_pignon.svg";
import chainage_angle from "../../../../images/svg/modenatures/chainage_angle.svg";
import soubassement from "../../../../images/svg/modenatures/soubassement.svg";
import sur_dalle from "../../../../images/svg/modenatures/sur_dalle.svg";

import roulants from "../../../../images/svg/volets/roulants.svg";
import battants from "../../../../images/svg/volets/battants.svg";
import coulissants from "../../../../images/svg/volets/coulissants.svg";

import une_pente from "../../../../images/svg/toitures/une_pente.svg";
import deux_pentes from "../../../../images/svg/toitures/deux_pentes.svg";
import trois_pentes from "../../../../images/svg/toitures/trois_pentes.svg";
import quatre_pentes from "../../../../images/svg/toitures/quatre_pentes.svg";
import mansarde from "../../../../images/svg/toitures/mansarde.svg";
import terrasse from "../../../../images/svg/toitures/terrasse.svg";

import battant from "../../../../images/svg/portails/battant.svg";
import coulissant from "../../../../images/svg/portails/coulissant.svg";
import portillon from "../../../../images/svg/portails/portillon.svg";

import a_gouttiere from "../../../../images/svg/gouttieres/a_gouttiere.svg";
import intersection from "../../../../images/svg/gouttieres/intersection.svg";

import gravillon from "../../../../images/jpg/cheminements/gravillon.jpg";
import beton_lave from "../../../../images/jpg/cheminements/beton_lave.jpg";
import pave from "../../../../images/jpg/cheminements/pave.png";
import dalle_alveole_engazonnee from "../../../../images/jpg/cheminements/dalle_alveole_engazonnee.jpg";
import goudron from "../../../../images/jpg/cheminements/goudron.jpg";
import beton_drainant from "../../../../images/jpg/cheminements/beton_drainant.jpg";



import RadioChoice from "../../../../components/form/input/RadioChoice";
import MultiSelect from "../../../../components/form/MultiSelect";

import {rals} from "../../../../resources/rals";
import {webers} from "../../../../resources/webers";
import Select from "../../../../components/form/select/Select";
import TextArea from "../../../../components/form/input/TextArea";

const MaterialsForm = props => {
    const constructionTypes = [
        {name: "R+CP", imagePath: r_cp},
        {name: "R+CA", imagePath: r_ca},
        {name: "R+x+CP", imagePath: rx_cp},
        {name: "R+x+CA", imagePath: rx_ca},
        {name: "R+TT", imagePath: r_tt},
        {name: "R+x+TT", imagePath: rx_tt},
    ];
    const annexes = [
        {name: "Garage", imagePath: garage},
        {name: "Abri de jardin", imagePath: abri},
        {name: "Veranda", imagePath: veranda},
        {name: "Piscine", imagePath: pool}
    ];
    const foundations = [
        {name: "Terre plein", imagePath: terre_plein},
        {name: "Vide sanitaire", imagePath: vide_sanitaire},
        {name: "Cave", imagePath: cave},
        {name: "Sous-sol", imagePath: sous_sol}
    ];
    const refurbishmentType = [
        {name: "Enduit gratté"},
        {name: "Enduit lissé", imagePath: null},
        {name: "Enduit écrasé"},
        {name: "Enduit projeté"},
        {name: "Enduit taloché"}
    ];
    const modenatureLocations = [
        {name: "Soubassement", imagePath: soubassement},
        {name: "Autour des menuiseries", imagePath: autour_des_menuiseries},
        {name: "Autour du pignon", imagePath: autour_du_pignon},
        {name: "Chaines d'angles", imagePath: chainage_angle},
        {name: "Sur dalles", imagePath: sur_dalle}

    ];
    const carpentriesMaterials = [
        "ALU",
        "PVC",
        "BOIS"
    ];
    const shutterTypes = [
        {type: "Roulants", imagePath: roulants},
        {type: "Battant", imagePath: battants},
        {type: "Coulissant", imagePath: coulissants}

    ];
    const roofTypes = [
        {name: "1 pan", imagePath: une_pente},
        {name: "2 pans", imagePath: deux_pentes},
        {name: "3 pans", imagePath: trois_pentes},
        {name: "4 pans", imagePath: quatre_pentes},
        {name: "Toit à la mansarde", imagePath: mansarde},
        {name: "Toit terrasse", imagePath: terrasse}
    ];
    const roofAspects = [
        {type: ["Brun rouge veilli"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Rouge"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Brun"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Brunie"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Sable champagne"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Ardoise"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Terrasse végétalisé"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Zinc"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Bac acier"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"}
    ];
    const gutterHeightTo = [
        {name: "à la gouttière", imagePath: a_gouttiere},
        {name: "à l'intersection de la toiture", imagePath: intersection}
    ];
    const gutterMaterials = ["ZINC", "ALU", "PVC"];

    const gutterColor = [
        {name: "à la gouttière", imagePath: null},
        {name: "à l'intersection de la toiture", imagePath: null}
    ];
    const portalTypes = [
        {name: "Battant", imagePath: battant},
        {name: "Coulissant", imagePath: coulissant}
    ];
    const portalMaterials = ["ALU", "PVC", "BOIS", "FER"];
    const gateTypes = [
        {name: "Battant", imagePath: portillon}
    ];

    const pathways = [
        {name: "Gravillon", imagePath: gravillon},
        {name: "Béton lavé", imagePath: beton_lave},
        {name: "Dalle alvéolé engazonnée", imagePath: dalle_alveole_engazonnee},
        {name: "Pavé", imagePath: pave},
        {name: "Goudron", imagePath: goudron},
        {name: "Béton drainant", imagePath: beton_drainant}
    ];

    const fences = [
        {name: "Mur"},
        {name: "Grillage rigide"},
        {name: "Haie vive"},
        {name: "Lame horizontale"},
        {name: "Lame verticale"},
        {name: "Barreau en fer forgé"}
    ]

    const fencesAttributes = [
        {legend: "Clôture avant (sur rue)", id: "frontFence", location: "frontFence"},
        {legend: "Clôture arrière", id: "backFence", location: "backFence"},
        {legend: "Clôture droite", id: "rightFence", location: "rightFence"},
        {legend: "Clôture gauche", id: "leftFence", location: "leftFence"},
        {legend: "Clôture entre lots", id: "betweenLotFence", location: "betweenLotFence"}
    ]

    return(
        <>
            <Container id={"typologie"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Typologie</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"type"}
                                    legend={"Type de construction"}
                                    name={"type"}
                                    items={
                                        constructionTypes.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.types.find(o => o.type === type.name) }
                                        })
                                    }
                                    onChange={props.handleType}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.inputs.types.map((type, i) => {
                                    return(
                                        <Row key={"type-"+i}>
                                            <Col  className="p-2">
                                                <img width={100} src={constructionTypes.find(o => o.name === type.type).imagePath} />
                                            </Col>
                                            <Col>
                                                <Text
                                                    name={"nbOfLevels"}
                                                    label={"Nombre de niveau"}
                                                    value={type.nbOfLevels}
                                                    onChange={e => props.handleType(e, type.type)}
                                                    disabled={type.disabled}
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"annexe"}
                                    legend={"Annexes"}
                                    name={"annexes"}
                                    items={
                                        annexes.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.annexes.indexOf(type.name) !== -1 }
                                        })
                                    }
                                    onChange={props.setInputTab}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"foundation"}
                                    legend={"Fondations"}
                                    name={"foundations"}
                                    items={
                                        foundations.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.foundations.indexOf(type.name) !== -1 }
                                        })
                                    }
                                    onChange={props.setInputTab}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"typologieComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.typologieComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id="ravalement" className="mb-4 bg-blue-grey  overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Ravalement</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <RadioChoice
                                    legend={"Type de ravalement"}
                                    id={"refurbishmentType"}
                                    name={"type"}
                                    items={
                                        refurbishmentType.map(type => {
                                            return {
                                                label: type.name,
                                                value: type.name,
                                                imagePath: type.imagePath,
                                                checked: props.inputs.refurbishment.type === type.name
                                            }
                                        })
                                    }
                                    onChange={e => props.setInputObject(e, "refurbishment")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    label={"Couleur"}
                                    options={webers.map(color => color.name)}
                                    name={"color"}
                                    value={props.inputs.refurbishment.color}
                                    onChange={e => props.setInputObject(e, "refurbishment")}
                                    required
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"refurbishmentComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.refurbishmentComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"modenatures"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Modénature</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"modenatureLocation"}
                                    legend={"Emplacement"}
                                    name={"location"}
                                    items={
                                        modenatureLocations.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.modenatures.find(o => o.location === type.name) }
                                        })
                                    }
                                    onChange={props.handleModenature}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.inputs.modenatures.map((modenature, i) => {
                                    return(
                                        <Row key={"modenature-"+i}>
                                            <Col  className="p-2">
                                                <img width={100} src={modenatureLocations.find(o => o.name === modenature.location).imagePath} />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"type"}
                                                    options={refurbishmentType.map(type => type.name)}
                                                    name={"type"}
                                                    value={modenature.type}
                                                    onChange={e => props.handleModenature(e, modenature.location)}
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Couleur"}
                                                    options={webers.map(color => color.name)}
                                                    name={"color"}
                                                    value={modenature.color}
                                                    onChange={e => props.handleModenature(e, modenature.location)}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"modenatureComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.modenatureComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"menuiseries"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Menuiserie</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    legend={"Matériaux"}
                                    id={"carpentryMaterial"}
                                    name={"materials"}
                                    items={
                                        carpentriesMaterials.map(material => {
                                            return {
                                                label: material,
                                                value: material,
                                                checked: props.inputs.carpentries.materials.indexOf(material) !== -1
                                            }
                                        })
                                    }
                                    onChange={e => props.setInputObjectTab(e, "carpentries")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    label={"Couleur"}
                                    options={rals.map(ral => ral.ralNumber)}
                                    name={"colors"}
                                    value={props.inputs.carpentries.colors}
                                    onChange={e => props.setInputObjectTab(e, "carpentries")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"carpentriComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.carpentriComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"volets"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Volet</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"shutterType"}
                                    legend={"Type"}
                                    name={"type"}
                                    items={
                                        shutterTypes.map(shutter => {
                                            return { label: shutter.type, value: shutter.type, imagePath: shutter.imagePath, checked: props.inputs.shutters.find(o=> o.type === shutter.type) }
                                        })
                                    }
                                    onChange={props.handleShutter}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.inputs.shutters.map((shutter, i) => {
                                    return(
                                        <Row key={"shutter-"+i}>
                                            <Col  className="p-2">
                                                <img width={100} src={shutterTypes.find(o => o.type === shutter.type).imagePath} />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Matériaux"}
                                                    options={carpentriesMaterials}
                                                    name={"material"}
                                                    value={shutter.material}
                                                    onChange={e => props.handleShutter(e, shutter.type)}
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Couleur"}
                                                    options={rals.map(color => color.ralNumber)}
                                                    name={"color"}
                                                    value={shutter.color}
                                                    onChange={e => props.handleShutter(e, shutter.type)}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"shutterComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.shutterComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"toitures"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Toiture</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"roofType"}
                                    legend={"Type"}
                                    name={"type"}
                                    items={
                                        roofTypes.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.roofs.find(o => o.type === type.name) }
                                        })
                                    }
                                    onChange={props.handleRoof}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.inputs.roofs.map((roof, i) => {
                                    return(
                                        <Row key={"roof-"+i}>
                                            <Col  className="p-2">
                                                <img width={100} src={roofTypes.find(o => o.name === roof.type).imagePath} />
                                            </Col>
                                            <Col>
                                                <Text
                                                    name={"m2unit"}
                                                    label={"m²/unitée"}
                                                    value={roof.m2unit}
                                                    onChange={e => props.handleRoof(e, roof.type)}
                                                />
                                            </Col>
                                            <Col>
                                                <Text
                                                    name={"slope"}
                                                    label={"Pente"}
                                                    value={roof.slope}
                                                    onChange={e => props.handleRoof(e, roof.type)}
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Aspect"}
                                                    options={roofAspects.map(aspect => aspect.type)}
                                                    name={"aspect"}
                                                    value={roof.aspect}
                                                    onChange={e => props.handleRoof(e, roof.type)}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"roofComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.roofComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"gouttieres"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Gouttières et descentes</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"gutterHeightTo"}
                                    legend={"Hauteur à"}
                                    name={"heightTo"}
                                    items={
                                        gutterHeightTo.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.gutter.heightTo === type.name }
                                        })
                                    }
                                    onChange={e => props.setInputObject(e, "gutter")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text
                                    name={"height"}
                                    label={"Hauteur à l'égout"}
                                    value={props.inputs.gutter.height}
                                    onChange={e => props.setInputObject(e, "gutter")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    label={"Matériaux"}
                                    options={gutterMaterials}
                                    id={"gutterMaterial"}
                                    name={"material"}
                                    value={props.inputs.gutter.material}
                                    onChange={e => props.setInputObject(e, "gutter")}
                                    required
                                />
                            </Col>
                            <Col>
                                <Select
                                    label={"Couleur"}
                                    options={rals.map(ral => ral.ralNumber)}
                                    name={"color"}
                                    value={props.inputs.gutter.color}
                                    onChange={e => props.setInputObject(e, "gutter")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"gutterComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.gutterComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"portails"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Portail</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"portalType"}
                                    legend={"Type"}
                                    name={"type"}
                                    items={
                                        portalTypes.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.portals.find(o => o.type === type.name) }
                                        })
                                    }
                                    onChange={props.handlePortal}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.inputs.portals.map((portal, i) => {
                                    return(
                                        <Row key={"portal-"+i}>
                                            <Col  className="p-2">
                                                <img width={100} src={portalTypes.find(o => o.name === portal.type).imagePath} />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Matériaux"}
                                                    options={portalMaterials}
                                                    id={"portalMaterial"}
                                                    name={"material"}
                                                    value={portal.material}
                                                    onChange={e => props.handlePortal(e, portal.type)}
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Couleur"}
                                                    options={rals.map(ral => ral.ralNumber)}
                                                    name={"color"}
                                                    value={portal.color}
                                                    onChange={e => props.handlePortal(e, portal.type)}
                                                    required
                                                />
                                            </Col>

                                            <Col>
                                                <Text
                                                    name={"width"}
                                                    label={"Largeur"}
                                                    value={portal.width}
                                                    onChange={e => props.handlePortal(e, portal.type)}
                                                />
                                            </Col>
                                            <Col>
                                                <Text
                                                    name={"height"}
                                                    label={"Hauteur"}
                                                    value={portal.height}
                                                    onChange={e => props.handlePortal(e, portal.type)}
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"portalComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.portalComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"portillons"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Portillon</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"gateType"}
                                    legend={"Type"}
                                    name={"type"}
                                    items={
                                        gateTypes.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.gates.find(o => o.type === type.name) }
                                        })
                                    }
                                    onChange={props.handleGate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.inputs.gates.map((gate, i) => {
                                    return(
                                        <Row key={"gate-"+i}>
                                            <Col  className="p-2">
                                                <img width={100} src={gateTypes.find(o => o.name === gate.type).imagePath} />
                                            </Col>
                                            <Col>
                                                <Select
                                                    label={"Matériaux"}
                                                    options={portalMaterials}
                                                    id={"portalMaterial"}
                                                    name={"material"}
                                                    value={gate.material}
                                                    onChange={e => props.handleGate(e, gate.type)}
                                                    required
                                                />
                                            </Col>
                                            <Col>
                                                <Col>
                                                    <Select
                                                        label={"Couleur"}
                                                        options={rals.map(ral => ral.ralNumber)}
                                                        name={"color"}
                                                        value={gate.color}
                                                        onChange={e => props.handleGate(e, gate.type)}
                                                        required
                                                    />
                                                </Col>

                                            </Col>
                                            <Col>
                                                <Text
                                                    name={"width"}
                                                    label={"Largeur"}
                                                    value={gate.width}
                                                    onChange={e => props.handleGate(e, gate.type)}
                                                />
                                            </Col>
                                            <Col>
                                                <Text
                                                    name={"height"}
                                                    label={"Hauteur"}
                                                    value={gate.height}
                                                    onChange={e => props.handleGate(e, gate.type)}
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"gateComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.gateComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"clotures"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Clôture</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        {fencesAttributes.map(fenceAttribute => {
                            return(
                                <div key={fenceAttribute.location}>
                                    <Row>
                                        <Col>
                                            <RadioChoice
                                                legend={fenceAttribute.legend}
                                                id={fenceAttribute.id}
                                                name={fenceAttribute.location}
                                                items={[
                                                    {label: "Non", value: "false", checked: props.inputs[fenceAttribute.location][fenceAttribute.location] === "false"},
                                                    {label: "Oui", value: "true", checked: props.inputs[fenceAttribute.location][fenceAttribute.location] === "true"}
                                                ]}
                                                onChange={e => props.setInputObject(e, fenceAttribute.location)}
                                            />
                                        </Col>
                                    </Row>
                                    {props.inputs[fenceAttribute.location][fenceAttribute.location] === "true" &&
                                        <>
                                            <Row>
                                                <Col>
                                                    <CheckboxChoice
                                                        id={fenceAttribute.id + "-fences"}
                                                        legend={"Type"}
                                                        name={"type"}
                                                        items={
                                                            fences.map(fence => {
                                                                return { label: fence.name, value: fence.name, imagePath: fence.imagePath, checked: props.inputs[fenceAttribute.location].fences.find(o=> o.type === fence.type) }
                                                            })
                                                        }
                                                        onChange={e => props.handleFences(e, fenceAttribute.location)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {
                                                    props.inputs[fenceAttribute.location].fences.map((fence, i) => {
                                                        return(
                                                            <Row key={"frontFence-"+i}>
                                                                <Col  className="p-2">
                                                                    <p>{fence.type}</p>
                                                                    {/*<img width={100} src={shutterTypes.find(o => o.type === shutter.type).imagePath} />*/}
                                                                </Col>
                                                                <Col>
                                                                    <Text
                                                                        name={"height"}
                                                                        label={"Hauteur"}
                                                                        value={fence.height}
                                                                        onChange={e => props.handleFences(e, fenceAttribute.location, fence.type)}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Select
                                                                        label={"Couleur"}
                                                                        options={rals.map(ral => ral.ralNumber)}
                                                                        name={"color"}
                                                                        value={fence.color}
                                                                        onChange={e => props.handleFences(e, fenceAttribute.location, fence.type)}
                                                                        required
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </>
                                    }
                                </div>
                            )})
                        }
                        <Row>
                            <Col>
                                <TextArea
                                    name={"fenceComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.fenceComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>

            <Container id={"cheminements"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Cheminement</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"pathways"}
                                    name={"pathways"}
                                    items={
                                        pathways.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.pathways.indexOf(type.name) !== -1 }
                                        })
                                    }
                                    onChange={props.setInputTab}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"pathwayComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.pathwayComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container id={"description"} className="mb-4 bg-blue-grey overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Description</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <Text
                                    name={"projectShortDescription"}
                                    label={"Description du projet"}
                                    value={props.inputs.projectShortDescription}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"projectFullDescription"}
                                    label={"Description détaillée du projet"}
                                    value={props.inputs.projectFullDescription}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default MaterialsForm;