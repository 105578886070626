export const webers = [
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/000-150x150.jpg",
        "name": "Blanc 0000"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/001-150x150.jpg",
        "name": "Blanc Cassé 001"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/002-150x150.jpg",
        "name": "Rose Clair 002"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/005-150x150.jpg\" class=\"attachment-thumbnail size-thumbnail\" alt=\"hfghfg\" loading=\"lazy\" aria-describedby=",
        "name": "Pierre Rosée 005"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/006-150x150.jpg",
        "name": "Rose Foncée 006"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/007-150x150.jpg",
        "name": "Ocre Orangé 007"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/009-150x150.jpg",
        "name": "Beige 009"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/010-150x150.jpg",
        "name": "Beige – Ocre 010"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/012-150x150.jpg",
        "name": "Brun 012"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/013-150x150.jpg",
        "name": "Brun Foncé 013"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/015-150x150.jpg",
        "name": "Pierre Clair 015"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/016-150x150.jpg",
        "name": "Ton Pierre 016"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/017-150x150.jpg",
        "name": "Pierre Foncé 017"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/041-150x150.jpg",
        "name": "Crème 041"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/044-150x150.jpg",
        "name": "Brun Clair 044"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/049-150x150.jpg",
        "name": "Ocre Rouge 049"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/055-150x150.jpg",
        "name": "Terre Rouge 055"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/058-150x150.jpg",
        "name": "Rose Moyen 058"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/080-150x150.jpg",
        "name": "Ton Brique 080"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/081-150x150.jpg",
        "name": "Orange Foncé 081"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/082-150x150.jpg",
        "name": "Rosé Orangé 082"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/083-150x150.jpg",
        "name": "Orange Clair 083"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/086-150x150.jpg",
        "name": "Ocre Rosé 086"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/091-150x150.jpg",
        "name": "Gris Perle 091"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/092-150x150.jpg",
        "name": "Rose Antique 092"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/096-150x150.jpg",
        "name": "Brun Doré 096"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/097-150x150.jpg",
        "name": "Sable Blond 097"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/099-150x150.jpg",
        "name": "Gris Mouve 099"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/101-150x150.jpg",
        "name": "Jaune Dune 101"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/103-150x150.jpg",
        "name": "Orange Flamboyant 103"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/104-150x150.jpg",
        "name": "Beige Ambre 104"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/106-150x150.jpg",
        "name": "Rouge Tuile 106"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/118-150x150.jpg",
        "name": "Beige Bauxite 118"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/202-150x150.jpg",
        "name": "Cendré Beige Foncé 202"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/203-150x150.jpg",
        "name": "Cendré Beige Clair 203"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/204-150x150.jpg",
        "name": "Bleu Doux 204"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/207-150x150.jpg",
        "name": "Beige Clair 207"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/209-150x150.jpg",
        "name": "Gris Bleuté Clair 209"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/211-150x150.jpg",
        "name": "Blanc Bleuté 211"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/212-1-150x150.jpg",
        "name": "Terre Beige  212"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/215-150x150.jpg",
        "name": "Ocre Rompu 215"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/222-150x150.jpg",
        "name": "Beige Rosé 222"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/224-150x150.jpg",
        "name": "Beige Grisé 224"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/226-150x150.jpg",
        "name": "Rose Beige Clair 226"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/230-150x150.jpg",
        "name": "Doré Clair 230"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/231-150x150.jpg",
        "name": "Jaune Ivoire 231"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/232-150x150.jpg",
        "name": "Mordoré 232"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/240-150x150.jpg",
        "name": "Marron Moyen 240"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/247-150x150.jpg",
        "name": "Rose Cendré Clair 247"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/248-150x150.jpg",
        "name": "Rose Beige Moyen 248"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/251-150x150.jpg",
        "name": "Rose Chaud Pâle 251"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/252-150x150.jpg",
        "name": "Beige Pâle 252"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/255-150x150.jpg",
        "name": "Beige Rompu 255"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/258-150x150.jpg",
        "name": "Rose Ocré Moyen 258"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/268-150x150.jpg",
        "name": "Cendré Vert 268"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/272-150x150.jpg",
        "name": "Gris Coloré Froid 272"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/273-150x150.jpg",
        "name": "Gres Rosé 273"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/276-150x150.jpg",
        "name": "Gris Vert 276"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/277-150x150.jpg",
        "name": "Bleu Rompu 277"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/278-150x150.jpg",
        "name": "Bleu Thé Vert 278"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/279-150x150.jpg",
        "name": "Pierre Grisée 279"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/285-150x150.jpg",
        "name": "Terre D’Ocre 285"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/289-150x150.jpg",
        "name": "Cendre Vert Clair 289"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/295-150x150.jpg",
        "name": "Ocre Intense 295"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/297-150x150.jpg",
        "name": "Ocre Chaud 297"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/299-150x150.jpg",
        "name": "Orange Bauxite 299"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/301-150x150.jpg",
        "name": "Doré Chaud 301"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/303-150x150.jpg",
        "name": "Terre Rouge Brun 303"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/304-150x150.jpg",
        "name": "Ocre Doré 304"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/305-150x150.jpg",
        "name": "Jaune Orpiment 305"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/306-150x150.jpg",
        "name": "Jaune Tuffeau 306"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/307-150x150.jpg",
        "name": "Jaune Vert 307"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/310-150x150.jpg",
        "name": "Terre Jaune 310"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/311-150x150.jpg",
        "name": "Jaune Safran 311"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/312-150x150.jpg",
        "name": "Terre Orangé 312"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/313-150x150.jpg",
        "name": "Ocre Rouge Moyen 313"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/314-150x150.jpg",
        "name": "Ocre Toscane 314"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/315-150x150.jpg",
        "name": "Rouge Foncé 315"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/319-150x150.jpg",
        "name": "Terre Brulée 319"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/320-150x150.jpg",
        "name": "Rose Brun 320"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/321-150x150.jpg",
        "name": "Mauve Beige 321"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/323-150x150.jpg",
        "name": "Brique Orangé 323"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/324-150x150.jpg",
        "name": "Blanc Craie 324"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/325-150x150.jpg",
        "name": "Rouse Soutenu 325"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/327-150x150.jpg",
        "name": "Rouge Territe 327"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/330-150x150.jpg",
        "name": "Brique Rouge 330"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/343-150x150.jpg",
        "name": "Rouge Carmine 343"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/370-150x150.jpg",
        "name": "Blanc Calcaire 370"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/377-150x150.jpg",
        "name": "Roche Verte 377"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/495-150x150.jpg",
        "name": "Beige Schiste 495"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/507-150x150.jpg",
        "name": "Bleu Moraine 507"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/516-150x150.jpg",
        "name": "Argile Verte 516"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/545-150x150.jpg",
        "name": "Terre D’Arene 545"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/550-150x150.jpg",
        "name": "Bleu Oxyde 550"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/553-150x150.jpg",
        "name": "Ocre Mousse 553"
    },
    {
        "imagePath": "https://www.pro-facade.fr/wp-content/uploads/2017/03/574-150x150.jpg",
        "name": "Schiste Vert 574"
    }
];