import {useState} from "react";
import Checkbox from "./Checkbox";
import "./style.css";

const CheckboxChoice = props => {
    const [error, setError] = useState("");

    return(
        <div className="input-checkbox-group">
            <fieldset id={props.id}>
                {props.legend && <legend>{props.legend}</legend>}
                {   props.items &&
                props.items.map((item, i) => {
                    return(
                        <Checkbox
                            key={props.id+"-"+i}
                            id={props.id+"-"+i}
                            name={props.name}
                            label={item.label}
                            value={item.value}
                            imagePath={item.imagePath}
                            onChange={props.onChange}
                            checked={item.checked}
                        />
                    );
                })
                }
            </fieldset>
            {error && <p>{error}</p>}
        </div>
    );
}

export default CheckboxChoice;