import axios from "axios";
import "./land";

const errorHandler = err => {
    throw err;
}

let server = axios.create({
    baseURL: `${process.env.REACT_APP_APIURL || '/api'}`, //    baseURL: `${process.env.REACT_APP_APIURL || '/api'}`, 'http://localhost:5000/api'
    withCredentials: true
})

export default {
    server,

    test(landInputs, urbanismRulesInputs, applicantsInputs, materialsInputs, billingInputs){
        return server.post('/test', {
            landInputs: landInputs,
            urbanismRulesInputs: urbanismRulesInputs,
            applicantsInputs: applicantsInputs,
            materialsInputs: materialsInputs,
            billingInputs: billingInputs,
        }, {responseType: 'arraybuffer'})
        .then(response => response)
        .catch(server.errorHandler);
    },

    generexml(landInputs, applicantsInputs, materialsFormInputs){
        return server.post('/generexml', {
            landInputs: landInputs,
            applicantsInputs: applicantsInputs,
            materialsInputs: materialsFormInputs
        }, {responseType: 'arraybuffer'})
            .then(response => response)
            .catch(server.errorHandler);
    }

}