import "../../css/footer.css"
import {Row} from "react-bootstrap";

const Footer = props => {

    return(
        <footer className={"pt-3"}>
            <div>Contactez-nous</div>
            <div>Conditions d'utilisation</div>
            <div>À propos</div>
        </footer>
    );
}

export default Footer;