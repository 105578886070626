import "./style.css"
import Step from "./components/step/Step";
import Arrow from "../../images/js/Arrow";

const Stepper = props => {
    return(
        <div className="stepper">
            <div className="stepper-progress" style={{width: `${props.currentStep / (props.steps.length-1) *100}%`}}></div>
            {
                props.steps && props.steps.map(step => {
                    return (
                        <Step
                            key={"step-"+step.index}
                            title={step.title}
                            index={step.index}
                            completed={step.index <= props.currentStep}
                            onClick={() => props.setCurrentStep(step.index)}
                        />
                    );
                })
            }
        </div>
    )
}

export default Stepper;