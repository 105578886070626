import {useState} from "react";
import "./style.css";

const Date = props => {
    const [error, setError] = useState("");

    return(
        <>
            <div className="input-group">
                <input className="input-writeable" value={props.value} name={props.name} type={"date"} onChange={props.onChange} required />
                <span className="highlight"/>
                <span className="bar"/>
                <label className="text-label">{props.label}</label>
            </div>
            {error && <p>{error}</p>}
        </>
    );
}

export default Date;