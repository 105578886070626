import {useState} from "react";
import Radio from "./Radio";
import "./style.css";

const RadioChoice = props => {
    const [error, setError] = useState("");

    return(
        <div className="input-radio-group">
            <fieldset id={props.id}>
                {props.legend && <legend>{props.legend}</legend>}
                {   props.items &&
                    props.items.map((item, i) => {
                        return(
                            <Radio
                                key={props.name+"-"+i}
                                id={props.id+"-"+i}
                                name={props.name}
                                label={item.label}
                                value={item.value}
                                onChange={props.onChange}
                                checked={item.checked}
                                imagePath={item.imagePath}
                            />
                        );
                    })
                }
            </fieldset>
            {error && <p>{error}</p>}
        </div>
    );
}

export default RadioChoice;