import {useState} from "react";
import "./style.css";

const Radio = props => {
    const [error, setError] = useState("");

    return(
        <div className="input-radio">
            <label className="rad-label">
                <input
                    id={props.id}
                    value={props.value}
                    name={props.name}
                    checked={props.checked}
                    type={"radio"}
                    onChange={props.onChange}
                    className="rad-input"
                />
                {
                    props.imagePath?
                        <img src={props.imagePath} />
                    :
                        <div className="rad-design"></div>
                }

                <div className="rad-text">{props.label}</div>
            </label>
            {error && <p>{error}</p>}
        </div>

    );
}

export default Radio;