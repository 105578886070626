import Text from "../../../../components/form/input/Text";
import RadioChoice from "../../../../components/form/input/RadioChoice";
import Checkbox from "../../../../components/form/input/Checkbox";
import {
    Col,
    Container,
    Row
} from "react-bootstrap";
import CheckboxChoice from "../../../../components/form/input/CheckboxChoice";
import r_cp from "../../../../images/svg/types/r_cp.svg";
import r_ca from "../../../../images/svg/types/r_ca.svg";
import rx_cp from "../../../../images/svg/types/r_x_cp.svg";
import rx_ca from "../../../../images/svg/types/r_x_ca.svg";
import r_tt from "../../../../images/svg/types/r_tt.svg";
import rx_tt from "../../../../images/svg/types/r_x_tt.svg";
import roulants from "../../../../images/svg/volets/roulants.svg";
import battants from "../../../../images/svg/volets/battants.svg";
import coulissants from "../../../../images/svg/volets/coulissants.svg";
import une_pente from "../../../../images/svg/toitures/une_pente.svg";
import deux_pentes from "../../../../images/svg/toitures/deux_pentes.svg";
import trois_pentes from "../../../../images/svg/toitures/trois_pentes.svg";
import quatre_pentes from "../../../../images/svg/toitures/quatre_pentes.svg";
import mansarde from "../../../../images/svg/toitures/mansarde.svg";
import terrasse from "../../../../images/svg/toitures/terrasse.svg";
import Select from "../../../../components/form/select/Select";
import {webers} from "../../../../resources/webers";
import {rals} from "../../../../resources/rals";
import TextArea from "../../../../components/form/input/TextArea";
import a_gouttiere from "../../../../images/svg/gouttieres/a_gouttiere.svg";
import intersection from "../../../../images/svg/gouttieres/intersection.svg";
import {forwardRef} from "react";

const UrbanismRulesForm = forwardRef((props, ref) => {
    const authorizedCarpentriesMaterials = ["ALU", "PVC", "BOIS"];
    const forbiddenCarpentriesColors = ["Rouge", "Bleu"];
    const authorizedRoofColors = ["Brun rouge vieilli", "ardoisé"];
    const authorizedShutters = [
        {type: "Roulants", imagePath: roulants},
        {type: "Battant", imagePath: battants},
        {type: "Coulissant", imagePath: coulissants}

    ];
    const authorizedRoofs = [
        {name: "1 pan", imagePath: une_pente},
        {name: "2 pans", imagePath: deux_pentes},
        {name: "3 pans", imagePath: trois_pentes},
        {name: "4 pans", imagePath: quatre_pentes},
        {name: "Toit à la mansarde", imagePath: mansarde},
        {name: "Toit terrasse", imagePath: terrasse}
    ];

    const constructionPosibilities = [
        {name: "R+CP", imagePath: r_cp},
        {name: "R+CA", imagePath: r_ca},
        {name: "R+x+CP", imagePath: rx_cp},
        {name: "R+x+CA", imagePath: rx_ca},
        {name: "R+TT", imagePath: r_tt},
        {name: "R+x+TT", imagePath: rx_tt},
    ];

    const refurbishmentType = [
        {name: "Enduit gratté"},
        {name: "Enduit lissé", imagePath: null},
        {name: "Enduit écrasé"},
        {name: "Enduit projeté"},
        {name: "Enduit taloché"}
    ];

    const gutterHeightTo = [
        {name: "à la gouttière", imagePath: a_gouttiere},
        {name: "à l'intersection de la toiture", imagePath: intersection}
    ];

    const roofAspects = [
        {type: ["Brun rouge veilli"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Rouge"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Brun"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Brunie"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Sable champagne"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Ardoise"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Terrasse végétalisé"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Zinc"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"},
        {type: ["Bac acier"], imagePath: "http://townandcountryremovals.com/wp-content/uploads/2013/10/firefox-logo-200x200.png"}
    ];

    return(
        <div ref={ref}>
            <Container className="text-center m-3">
                <a href={"https://www.cadastre.gouv.fr/"} target={"_blank"}><button>Cadastre</button></a>
                <a href={"https://www.geoportail-urbanisme.gouv.fr/"} target={"_blank"}><button>Geoportail urbanisme</button></a>
                <a href={"https://www.google.fr/maps/"} target={"_blank"}><button>Google maps</button></a>
                <a href={"https://www.geoportail.gouv.fr/"} target={"_blank"}><button>Geoportail</button></a>
            </Container>

        <Container id={"urbanisme"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
            <Row className="justify-content-center">
                <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                    <p className="font-monospace font-4">Urbanisme</p>
                </Col>
                <Col sm={10} className="justify-content-center p-4 pb-5">
                    <div>
                        <RadioChoice
                            legend={"Construction"}
                            id={"projectType"}
                            name={"projectType"}
                            items={[{label: "Nouvelle construction", value: "newConstruction", checked: props.inputs.projectType === "newConstruction"}, {label: "Construction existante", value: "existingConstruction", checked: props.inputs.projectType === "existingConstruction"}]}
                            onChange={props.setInput}
                        />
                    </div>
                    <Row>
                        <Col>
                            <Text
                                name={"pluZone"}
                                label={"Zone du PLU/PLUi"}
                                value={props.inputs.pluZone}
                                onChange={props.setInput}
                            />
                        </Col>
                        <Col>
                            <Text
                                name={"pluLink"}
                                label={"Lien vers le PLU/PLUi"}
                                value={props.inputs.pluLink}
                                onChange={props.setInput}
                            />
                        </Col>
                    </Row>
                    <div>
                        <RadioChoice
                            legend={"ABF"}
                            id={"abf"}
                            name={"abf"}
                            items={[{label: "Non", value: "false",checked: props.inputs.abf === "false"}, {label: "Oui", value: "true", checked: props.inputs.abf === "true"}]}
                            onChange={props.setInput}
                        />
                    </div>
                    <Row>
                        <Col>
                            <TextArea
                                name={"urbanismComment"}
                                label={"Commentaire"}
                                value={props.inputs.urbanismComment}
                                onChange={props.setInput}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

        <Container id={"implantation"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Implantation des constructions</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <div>
                            <RadioChoice
                                legend={"Bande de constructibilité"}
                                id={"constructibilityBand"}
                                name={"constructibilityBand"}
                                items={[{label: "Non", value: "false",checked: props.inputs.constructibilityBand.exists === "false"}, {label: "Oui", value: "true", checked: props.inputs.constructibilityBand.exists === "true"}]}
                                onChange={(e) => props.handleIfExistsInput(e, "exists")}
                            />
                            {
                                props.inputs.constructibilityBand.exists === "true" &&
                                <Text
                                    name={"constructibilityBand"}
                                    label={"Longueur de la bande"}
                                    value={props.inputs.constructibilityBand.length}
                                    onChange={(e) => props.handleIfExistsInput(e, "length")}
                                />
                            }
                        </div>
                        <Row>
                            <Col>
                                <Text
                                    name={"distanceFromRoad"}
                                    label={"Distance par rapport aux voies"}
                                    value={props.inputs.distanceFromRoad}
                                    onChange={props.setInput}
                                />
                            </Col>
                            <Col>
                                <Text
                                    name={"distanceFromLimits"}
                                    label={"Distance par rapport aux limites"}
                                    value={props.inputs.distanceFromLimits}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text
                                    name={"distanceFromLandEnd"}
                                    label={"Distance par rapport au fond de parcelle"}
                                    value={props.inputs.distanceFromLandEnd}
                                    onChange={props.setInput}
                                />
                            </Col>
                            <Col>
                                <Text
                                    name={"distanceBetweenConstructions"}
                                    label={"Distance entre constructions"}
                                    value={props.inputs.distanceBetweenConstructions}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"implantingComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.implantingComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        <Container id={"typologie"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
            <Row className="justify-content-center">
                <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                    <p className="font-monospace font-4">Typologie</p>
                </Col>
                <Col sm={10} className="justify-content-center p-4 pb-5">
                    <Row>
                        <Col>
                            <CheckboxChoice
                                id={"constructionPossibilities"}
                                legend={"Construction possible"}
                                name={"type"}
                                items={
                                    constructionPosibilities.map(constructionPosibility => {
                                        return { label: constructionPosibility.name, value: constructionPosibility.name, imagePath: constructionPosibility.imagePath, checked: props.inputs.constructionPossibilities.find(o => o.type === constructionPosibility.name) }
                                    })
                                }
                                onChange={props.handleConstructionPossibilities}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {
                            props.inputs.constructionPossibilities.map((constructionPossibility, i) => {
                                return(
                                    <Row key={"type-"+i}>
                                        <Col  className="p-2">
                                            <img width={100} src={constructionPosibilities.find(o => o.name === constructionPossibility.type).imagePath} />
                                        </Col>
                                        <Col>
                                            <Text
                                                name={"nbOfLevels"}
                                                label={"Nombre de niveau"}
                                                value={constructionPossibility.nbOfLevels}
                                                onChange={e => props.handleConstructionPossibilities(e, constructionPossibility.type)}
                                                disabled={constructionPossibility.disabled}
                                            />
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </Row>
                    <Row>
                        <Col>
                            <TextArea
                                name={"typologyComment"}
                                label={"Commentaire"}
                                value={props.inputs.typologyComment}
                                onChange={props.setInput}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

            <Container id={"Hauteur"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Hauteur</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"gutterHeightTo"}
                                    legend={"Hauteur d'égout à"}
                                    name={"gutterTo"}
                                    items={
                                        gutterHeightTo.map(type => {
                                            return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.gutterTo === type.name }
                                        })
                                    }
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Text
                                    name={"gutterMaxHeight"}
                                    label={"Hauteur maximale à l'égout"}
                                    value={props.inputs.gutterMaxHeight}
                                    onChange={props.setInput}
                                />
                            </Col>
                            <Col>
                                <Text
                                    name={"ridgeMaximalHeight"}
                                    label={"Hauteur maximale au faitage"}
                                    value={props.inputs.ridgeMaximalHeight}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                        <div>
                            <RadioChoice
                                legend={"Volume enveloppe toiture (VET)"}
                                id={"roofEnvelopVolume-type"}
                                name={"roofEnvelopVolume"}
                                items={[{label: "Non", value: "false",checked: props.inputs.roofEnvelopVolume.exists === "false"}, {label: "Oui", value: "true", checked: props.inputs.roofEnvelopVolume.exists === "true"}]}
                                onChange={(e, fieldname) => props.handleIfExistsInput(e, "exists")}
                            />
                            {
                                props.inputs.roofEnvelopVolume.exists === "true" &&
                                <Text
                                    name={"roofEnvelopVolume"}
                                    label={"Hauteur"}
                                    value={props.inputs.roofEnvelopVolume.height}
                                    onChange={(e) => props.handleIfExistsInput(e, "height")}
                                />
                            }
                        </div>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"heightComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.heightComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        <Container className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Emprise et espace libre</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <Text
                                    name={"maximalFootPrint"}
                                    label={"Emprise au sol maximum"}
                                    value={props.inputs.maximalFootPrint}
                                    onChange={props.setInput}
                                />
                                {
                                    props.inputs.maximalFootPrint !== "" &&
                                    <Col>
                                        <p>Soit {props.area*props.inputs.maximalFootPrint/100} m²</p>
                                    </Col>
                                }
                            </Col>
                        </Row>
                        <div>
                            <Col>
                                <Text
                                    name={"openSpaceCoefficient"}
                                    label={"Coefficient de pleine terre"}
                                    value={props.inputs.openSpaceCoefficient}
                                    onChange={props.setInput}
                                />
                            </Col>
                            {
                                props.inputs.openSpaceCoefficient !== "" &&
                                <Col>
                                    <p>Soit {props.area*props.inputs.openSpaceCoefficient/100} m²</p>
                                </Col>
                            }
                        </div>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"floorSpacesComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.floorSpacesComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        <Container id="ravalement" className="mb-4 bg-blue-grey  overflow-hidden boxShadow">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Ravalement</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5">
                        <Row>
                            <Col>
                                <CheckboxChoice
                                    id={"refurbishment-type"}
                                    legend={"Type de ravalement"}
                                    name={"types"}
                                    items={
                                        refurbishmentType.map(type => {
                                            return { label: type.name, value: type.name, checked: props.inputs.refurbishment.types.indexOf(type.name) !== -1 }
                                        })
                                    }
                                    onChange={e => props.setInputObjectTab(e, "refurbishment")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Select
                                    label={"Couleur"}
                                    options={webers.map(color => color.name)}
                                    name={"colors"}
                                    value={props.inputs.refurbishment.color}
                                    onChange={e => props.setInputObjectTab(e, "refurbishment")}
                                    required
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextArea
                                    name={"refurbishmentComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.refurbishmentComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        <Container id={"carpentrie"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
            <Row className="justify-content-center">
                <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                    <p className="font-monospace font-4">Menuiseries</p>
                </Col>
                <Col sm={10} className="justify-content-center p-4 pb-5">
                    <RadioChoice
                        legend={"Ouverture plus haute que large"}
                        id={"openingsHigherThanWide"}
                        name={"openingsHigherThanWide"}
                        items={[
                            {label: "Non", value: "false", checked: props.inputs.carpentries.openingsHigherThanWide.exists === "false"},
                            {label: "Oui", value: "true", checked: props.inputs.carpentries.openingsHigherThanWide.exists === "true"}
                        ]}
                        onChange={e => props.handleCarpentriesIfExistsInput(e, "exists")}
                    />
                    {
                        props.inputs.carpentries.openingsHigherThanWide.exists === "true" &&
                        <Text
                            name={"openingsHigherThanWide"}
                            label={"Rapport minimum"}
                            value={props.inputs.carpentries.openingsHigherThanWide.minimumRelation}
                            onChange={e => props.handleCarpentriesIfExistsInput(e, "minimumRelation")}
                        />
                    }
                    <CheckboxChoice
                        legend={"Matériaux autorisés"}
                        id={"authorizedCarpentriesMaterials"}
                        name={"authorizedMaterials"}
                        items={
                            authorizedCarpentriesMaterials.map(material => {
                                return {
                                    label: material,
                                    value: material,
                                    checked: props.inputs.carpentries.authorizedMaterials.indexOf(material) !== -1
                                }
                            })
                        }
                        onChange={props.handleCarpentriesArrayInput}
                    />
                    <Row>
                        <Col>
                            <Select
                                label={"Couleur"}
                                options={rals.map(ral => ral.ralNumber)}
                                name={"colors"}
                                value={props.inputs.carpentries.colors}
                                onChange={e => props.setInputObjectTab(e, "carpentries")}
                                required
                            />
                        </Col>
                    </Row>

                    <CheckboxChoice
                        id={"authorizedShutters"}
                        legend={"Volets autorisés"}
                        name={"authorizedShutters"}
                        items={
                            authorizedShutters.map(shutter => {
                                return { label: shutter.type, value: shutter.type, imagePath: shutter.imagePath, checked: props.inputs.carpentries.authorizedShutters.indexOf(shutter.type) !== -1 }
                            })
                        }
                        onChange={props.handleCarpentriesArrayInput}
                    />
                    <Row>
                        <Col>
                            <TextArea
                                name={"carpentrieComment"}
                                label={"Commentaire"}
                                value={props.inputs.carpentrieComment}
                                onChange={props.setInput}
                            />
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>

        <Container id={"roof"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
            <Row className="justify-content-center">
                <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                    <p className="font-monospace font-4">Toiture</p>
                </Col>
                <Col sm={10} className="justify-content-center p-4 pb-5">
                    <CheckboxChoice
                        id={"authorizedRoofs"}
                        legend={"Type de toiture autorisé"}
                        name={"authorizedRoofs"}
                        items={
                            authorizedRoofs.map(type => {
                                return { label: type.name, value: type.name, imagePath: type.imagePath, checked: props.inputs.roofing.authorizedRoofs.indexOf(type.name) !== -1 }
                            })
                        }
                        onChange={props.handleRoofingArrayInput}
                    />

                    <p>Prente de toit autorisées</p>
                    <Row>
                        <Col>
                            <Text
                                name={"authorizedRoofSlop"}
                                label={"Minimum"}
                                fieldname={"min"}
                                value={props.inputs.roofing.authorizedRoofSlop.min}
                                onChange={e => props.handleSubRoofingInput(e, "min")}
                            />
                        </Col>
                        <Col>
                            <Text
                                name={"authorizedRoofSlop"}
                                label={"Maximum"}
                                fieldname={"max"}
                                value={props.inputs.roofing.authorizedRoofSlop.max}
                                onChange={e => props.handleSubRoofingInput(e, "max")}
                            />
                        </Col>
                    </Row>
                    <Col>
                        <Select
                            label={"Aspect"}
                            options={roofAspects.map(aspect => aspect.type)}
                            name={"authorizedColors"}
                            value={props.inputs.roofing.authorizedColors}
                            onChange={e => props.handleSubRoofingInput(e, "authorizedColors")}
                            required
                        />
                    </Col>
                    <Text
                        name={"unitPerSquareMeter"}
                        label={"Unités minimum par m²"}
                        value={props.inputs.roofing.unitPerSquareMeter}
                        onChange={props.handleRoofingInput}
                    />
                    <Row>
                        <Col>
                            <TextArea
                                name={"roofComment"}
                                label={"Commentaire"}
                                value={props.inputs.roofComment}
                                onChange={props.setInput}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

        <Container id={"fence"} className="mb-4 bg-blue-grey rounded-3 overflow-hidden">
            <Row className="justify-content-center">
                <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                    <p className="font-monospace font-4">Clôtures</p>
                </Col>
                <Col sm={10} className="justify-content-center p-4 pb-5">
                    <Row>
                        <Col>
                            <TextArea
                                name={"fenceComment"}
                                label={"Commentaire"}
                                value={props.inputs.fenceComment}
                                onChange={props.setInput}
                            />
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </Container>

        <Container id={"carPark"} className="mb-4 bg-blue-grey rounded-4 overflow-hidden">
                <Row className="justify-content-center">
                    <Col sm={2} className="form-gradient d-flex align-items-center justify-content-center">
                        <p className="font-monospace font-4">Stationnement</p>
                    </Col>
                    <Col sm={10} className="justify-content-center p-4 pb-5 position-relative">
                        <Text
                            name={"minCarParkPlace"}
                            label={"Nombre de place total (minimum)"}
                            value={props.inputs.minCarParkPlace}
                            onChange={props.setInput}
                        />

                        <RadioChoice
                            legend={"Place couverte"}
                            id={"coveredCarParkPlace"}
                            name={"coveredCarParkPlace"}
                            items={[{label: "Non", value: "false",checked: props.inputs.coveredCarParkPlace.exists === "false"}, {label: "Oui", value: "true", checked: props.inputs.coveredCarParkPlace.exists === "true"}]}
                            onChange={(e, fieldname) => props.handleIfExistsInput(e, "exists")}
                        />
                        {
                            props.inputs.coveredCarParkPlace.exists === "true" &&
                            <Text
                                name={"coveredCarParkPlace"}
                                label={"Nombre de place couverte (minimum)"}
                                value={props.inputs.coveredCarParkPlace.min}
                                onChange={(e, fieldname) => props.handleIfExistsInput(e, "min")}
                            />
                        }
                        <Row>
                            <Col>
                                <TextArea
                                    name={"carParkComment"}
                                    label={"Commentaire"}
                                    value={props.inputs.carParkComment}
                                    onChange={props.setInput}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
})

export default UrbanismRulesForm;