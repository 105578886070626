import {useState} from "react";
import "./style.css";

const Checkbox = props => {
    const [error, setError] = useState("");

    return(
        <div className={"input-checkbox"}>
            <input type="checkbox" id={"myCheckbox" + props.id} value={props.value} name={props.name} onChange={props.onChange} checked={props.checked}/>
            <label className={"checkbox-label"} htmlFor={"myCheckbox" + props.id}>
                { props.imagePath? <img src={props.imagePath}/> : props.label }
            </label>
        </div>
    );
}

export default Checkbox;