import "./style.css";

const Step = props => {
    return(
        <div className="step-wrapper">
            <div className={"screen-indicator" + (props.completed?" completed":"")} onClick={props.onClick}>
                {props.index+1}
            </div>
            <p className={"step-title" + (props.completed?" step-title-completed":"")}>{props.title}</p>
        </div>
    );
}
//<p className={"stepTitle" + (props.active?" stepTitle-active":"")}>{props.title}</p>
export default Step;