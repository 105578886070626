import {useState} from "react";
import "./style.css";

const Text = props => {
    const [error, setError] = useState("");

    return(
        <div className="input-group">
            <input
                className="input-writeable"
                value={props.value}
                name={props.name}
                type={"text"}
                onChange={props.onChange}
                disabled={props.disabled}
                required
            />
            <span
                className="bar"
            />
            <label
                className="text-label">
                {props.label}
            </label>
            {error && <p>{error}</p>}
        </div>
    );
}

export default Text;